import { Component, OnInit, Input } from '@angular/core';
import { Doctor } from 'src/app/model/Richiesta';

@Component({
  selector: 'app-topper-info-doctor',
  templateUrl: './topper-info-doctor.component.html',
  styleUrls: ['./topper-info-doctor.component.scss'],
})
export class TopperInfoDoctorComponent implements OnInit {
  @Input() doctor!: Doctor;
  @Input() idRichiesta!: Number | String;

  constructor() {}

  ngOnInit(): void {}
}
