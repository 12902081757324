import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MedicData } from 'src/app/model/MedicProfile';
import * as fromApp from 'src/app/store/app.reducer';
import { environment } from 'src/environments/environment';
import * as MedicProfileActions from './store/medic-profile.actions';

@Component({
  selector: 'app-medic-profile',
  templateUrl: './medic-profile.component.html',
  styleUrls: ['./medic-profile.component.scss'],
})
export class MedicProfileComponent implements OnInit, OnDestroy {
  public loggedIn: boolean = false;
  private storeSubscription: Subscription;
  private medicId: string = '';
  private paramSubscription: any;
  public doctorFromStore: MedicData | null;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      if (idParam) {
        this.medicId = idParam;
        console.log('Medic id from router:', this.medicId);
        this.store.dispatch(
          MedicProfileActions.fetchMedicProfile({ medicId: this.medicId })
        );
      } else {
        this.router.navigate(['/richieste']);
      }
    });

    this.storeSubscription = this.store
      .select('medicProfile')
      .subscribe((data) => {
        this.loading = data.loading;
        this.doctorFromStore = data.medic;
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  getDoctorAvatar() {
    return `${environment.NEW_API}/doctors/${this.doctorFromStore?.id}/image`;
  }

  goBack() {
    this.location.back();
  }
}
