<footer>
  <div class="container main-container">

    <div class="d-flex justify-content-between align-items-center">

      <div class="d-flex align-items-center">
        <img class="logo-footer" src="./assets/images/logo-drfeel-bianco.png" alt="Dr.Feel" />
        <p class="ml-4">© 2023 drfeel.com All rights reserved</p>
      </div>

      <div>
        <ul class="legal d-flex align-items-center">
          <li class="item-copy">
            <a target="_black" class="mr-1" routerLink="/cookiepolicy">Cookie Policy</a>
          </li>
          <li class="item-copy">
            <a target="_black" class="mr-1" routerLink="/terminiecondizioni">Termini e Condizioni</a>
          </li>
          <li class="item-copy">
            <a target="_black" class="" routerLink="/privacypolicy">Privacy Policy</a>
          </li>
        </ul>
      </div>

    </div>

  </div>
</footer>
