<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div *ngIf="me && !loading" class="info-profilo">
  <div class="info-profilo-form" *ngIf="!loading">
    <form form #f="ngForm" (ngSubmit)="onSaveProfile(f)" class="row">
      <!-- ERRORS -->
      <div class="col-12">
        <div class="alert alert-danger" role="alert"
          *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
          <h5>Nome</h5>
          <ul>
            <li *ngIf="firstName.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
          <h5>Cognome</h5>
          <ul>
            <li *ngIf="lastName.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="fiscalCode.invalid && fiscalCode.touched">
          <h5>Codice Fiscale</h5>
          <ul>
            <li *ngIf="fiscalCode.errors?.['required']">
              il campo è obbligatorio
            </li>
            <li *ngIf="!!fiscalCode.errors?.['pattern']">
              Codice fiscale errato
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert"
          *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
          <h5>Data di Nascita</h5>
          <ul>
            <li *ngIf="birthDate.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="bornCity.invalid && (bornCity.dirty || bornCity.touched)">
          <h5>Luogo di Nascita</h5>
          <ul>
            <li *ngIf="bornCity.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="gender.invalid && (gender.dirty || gender.touched)">
          <h5>Sesso</h5>
          <ul>
            <li *ngIf="gender.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="email.invalid && email.touched">
          <h5>Email</h5>
          <ul>
            <li *ngIf="email.errors?.['required']">il campo è obbligatorio</li>
            <li *ngIf="!!email.errors?.['pattern']">Email errata</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="phoneNumber.invalid && phoneNumber.touched">
          <h5>Cellulare</h5>
          <ul>
            <li *ngIf="phoneNumber.errors?.['required']">
              il campo è obbligatorio
            </li>
            <li *ngIf="phoneNumber.errors?.['minlength']">
              Numero di telefono errato
            </li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="street.invalid && (street.dirty || street.touched)">
          <h5>Indirizzo</h5>
          <ul>
            <li *ngIf="street.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="zip.invalid && (zip.dirty || zip.touched)">
          <h5>Codice Postale</h5>
          <ul>
            <li *ngIf="zip.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="city.invalid && (city.dirty || city.touched)">
          <h5>Città</h5>
          <ul>
            <li *ngIf="city.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="region.invalid && (region.dirty || region.touched)">
          <h5>Regione</h5>
          <ul>
            <li *ngIf="region.errors?.['required']">il campo è obbligatorio</li>
          </ul>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="country.invalid && (country.dirty || country.touched)">
          <h5>Nazione</h5>
          <ul>
            <li *ngIf="country.errors?.['required']">
              il campo è obbligatorio
            </li>
          </ul>
        </div>
      </div>
      <!-- ERRORS END -->

      <div class="form-floating col-12 col-md-4">
        <input required #firstName="ngModel" name="firstName" class="form-control no-box" id="firstName" placeholder="Nome"
          type="text" [ngModel]="me.firstName" />
        <label *ngIf="!firstName.invalid" for="firstName">Nome*</label>
        <label class="text-danger" *ngIf="firstName.invalid" for="firstName">Nome*</label>
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #lastName="ngModel" name="lastName" class="form-control no-box" id="lastName" placeholder="Cognome"
          type="text" [ngModel]="me.lastName" />
        <label *ngIf="!lastName.invalid" for="lastName">Cognome*</label>
        <label class="text-danger" *ngIf="lastName.invalid" for="lastName">Cognome*</label>
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #fiscalCode="ngModel" name="fiscalCode" class="form-control no-box" id="fiscalCode"
          placeholder="Codice fiscale" type="text" [ngModel]="me.fiscalCode"
          pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$" />
        <label *ngIf="!fiscalCode.invalid" for="fiscalCode">Codice Fiscale*</label>
        <label class="text-danger" *ngIf="fiscalCode.invalid" for="fiscalCode">Codice Fiscale*</label>
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #birthDate="ngModel" name="birthDate" class="form-control no-box" id="birthDate" placeholder="Data"
          type="date" [ngModel]="me.birthDate" />
        <label *ngIf="!birthDate.invalid" for="birthDate">Data di Nascita*</label>
        <label class="text-danger" *ngIf="birthDate.invalid" for="birthDate">Data di Nascita*</label>
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #bornCity="ngModel" name="bornCity" class="form-control no-box" id="bornCity"
          placeholder="Luogo di Nascita" type="text" [ngModel]="me.bornCity" />
        <label *ngIf="!bornCity.invalid">Luogo di Nascita*</label>
        <label class="text-danger" *ngIf="bornCity.invalid">Luogo di Nascita*</label>
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <select required #gender="ngModel" class="form-select no-box do3-fw600" name="gender" id="gender"
          [ngModel]="me.gender">
          <option value="MALE">Uomo</option>
          <option value="FEMALE">Donna</option>
          <option value="OTHER">Preferisco non rispondere</option>
        </select>
        <label *ngIf="!gender.invalid" for="gender">Sesso*</label>
        <label class="text-danger" *ngIf="gender.invalid" for="gender">Sesso*</label>
      </div>
      
      <div class="form-floating col-12 col-md-6">
        <input required #email="ngModel" name="email" class="form-control no-box" id="email" placeholder="Email"
          [ngModel]="me.email" type="email" disabled="true"
          pattern='((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))' />
        <label *ngIf="!email.invalid" for="email">Email*</label>
        <label class="text-danger" *ngIf="email.invalid" for="email">Email*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-6">
        <input required #phoneNumber="ngModel" name="phoneNumber" class="form-control no-box tel" id="phoneNumber"
          placeholder="Cellulare" type="number" [ngModel]="me.phoneNumber" minlength="10" pattern="^(\+?\d{10,14})$" />
        <label *ngIf="!phoneNumber.invalid" for="phoneNumber">Cellulare*</label>
        <label class="text-danger" *ngIf="phoneNumber.invalid" for="phoneNumber">Cellulare*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-8">
        <input required #street="ngModel" name="street" class="form-control no-box" id="street" placeholder="Indirizzo"
          type="text" [ngModel]="me.street" />
        <label *ngIf="!street.invalid" for="street">Indirizzo*</label>
        <label class="text-danger" *ngIf="street.invalid" for="street">Indirizzo*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #zip="ngModel" name="zip" class="form-control no-box" id="zip" placeholder="Codice Postale"
          type="number" [ngModel]="me.zip" />
        <label *ngIf="!zip.invalid" for="zip">Codice Postale*</label>
        <label class="text-danger" *ngIf="zip.invalid" for="zip">Codice Postale*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #city="ngModel" name="city" class="form-control no-box" id="city" placeholder="Città" type="text"
          [ngModel]="me.city" />
        <label *ngIf="!city.invalid" for="city">Città*</label>
        <label class="text-danger" *ngIf="city.invalid" for="city">Città*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #region="ngModel" name="region" class="form-control no-box" id="region" placeholder="Regione"
          type="text" [ngModel]="me.region" />
        <label *ngIf="!region.invalid" for="street">Regione*</label>
        <label class="text-danger" *ngIf="region.invalid" for="street">Regione*</label>
      
      </div>
      
      <div class="form-floating col-12 col-md-4">
        <input required #country="ngModel" name="country" class="form-control no-box" id="country" placeholder="Regione"
          type="text" [ngModel]="me.country" />
        <label *ngIf="!country.invalid" for="country">Nazione*</label>
        <label class="text-danger" *ngIf="country.invalid" for="country">Nazione*</label>
      
      </div>

      <small>(*) il campo è obbligatorio</small>

      <div class="d-flex justify-content-between mt-5">
        <button class="do3-secondary-btn light-blue ml-0" [disabled]="!f.valid" type="submit">
          SALVA MODIFICHE
        </button>
        <!-- <button class="do3-secondary-btn light-blue ml-0" type="submit">SALVA MODIFICHE</button> -->
        <!-- <button class="do3-secondary-btn red">ELIMINA IL TUO ACCOUNT</button> -->
      </div>
    </form>
  </div>
</div>