import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-single-anamnesi',
  templateUrl: './single-anamnesi.component.html',
  styleUrls: ['./single-anamnesi.component.scss']
})
export class SingleAnamnesiComponent implements OnInit {
  @Input() richiesta: any | null;

  public anamnesi: any;
  public isLoading: boolean = true;
  public openPanel: boolean = false;
  public isMobile: boolean = false;
  private breakPoint: number = 767;

  constructor(private http: HttpClient, private breakpointObserver: BreakpointObserver) { }
  ngOnInit(): void {
    this.breakpointObserver.observe([`(max-width: ${this.breakPoint}px)`]).subscribe((state: any) => {
      this.isMobile = state.matches;
    });
  }

  getAnamnesi(id: string) {
    this.openPanel = !this.openPanel
    return this.http
      .get(
        `${environment.NEW_API}/requests/${id}/triage`
      )
      .subscribe((data: any) => {
        console.log(data.data)
        this.isLoading = false
        return this.anamnesi = data.data
      }
      )
  }

  

}
