import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import * as RichiesteActions from 'src/app/features/richieste/store/richieste.actions';
import {
  postProfileInfos,
  resetProfile,
} from 'src/app/features/profilo/store/profilo.actions';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { UserCredential } from '@angular/fire/auth';
import { UserData } from 'src/app/model/UserData';
import * as fromApp from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pipe } from 'rxjs';

@Injectable()
export class AuthEffects {
  login$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService
          .login(action.email, action.password)
          .then(async (res: UserCredential) => {
            const token = await res.user.getIdToken();
            // console.log('user:', res.user.toJSON());
            const userData: UserData = {
              uid: res.user.uid,
              email: res.user.email,
              refreshToken: res.user.refreshToken,
              accessToken: token,
            };
            console.log('userData:', userData);

            return AuthActions.loginSuccess({ userData });
          })
          .catch((err) => {
            console.error('err:', err);
            return AuthActions.loginFailed({ error: err });
          })
      )
    )
  );

  checkCoupon$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.couponCheck),
      switchMap((action) =>
        this.authService
          .checkCoupon(action.coupon, action.email)
          .then(async () => {
            return AuthActions.register({
              email: action.email,
              password: action.password,
              name: action.name,
              lastname: action.lastname,
              coupon: action.coupon,
              phonenumber: action.phonenumber,
            });
          })
          .catch((err) => {
            console.log('err:', err);
            return AuthActions.couponCheckFail({ error: err });
          })
      )
    )
  );

  register$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      switchMap((action) =>
        this.authService
          .register(action.email, action.password)
          .then(async (res: UserCredential) => {
            return AuthActions.registerSuccess({
              name: action.name,
              lastname: action.lastname,
              coupon: action.coupon,
              phonenumber: action.phonenumber,
            });
          })
          .catch((err) => {
            console.log('err:', err);
            return AuthActions.registerFail({ error: err });
          })
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(async (userData) => {
          this.authService.saveAuth(userData);
        })
        //   map(() => AuthActions.loginRedirect())
      ),
    { dispatch: false }
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        tap(async (action) => {
          let body: any = {
            name: action.name,
            lastname: action.lastname,
            phonenumber: action.phonenumber,
          };
          //   this.authService.saveAuth(action.userData);
          if (action.coupon) {
            body.coupon = action.coupon;
          }
          this.store.dispatch(postProfileInfos(body));

          //   this.router.navigate(['/privacy']);
        })
        //   map(() => AuthActions.loginRedirect())
      ),
    { dispatch: false }
  );

  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      mapTo(this.authService.getToken()),
      filter<any>((accessToken) => !!accessToken),
      switchMap((userData) => {
        console.log('userData from autoLogin$:', userData);
        return [AuthActions.loginSuccess({ userData })];
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.authService.cleanAuth();
          this.store.dispatch(RichiesteActions.resetRichieste());
          this.store.dispatch(resetProfile());
          console.log('logout');
        })
      ),
    { dispatch: false }
  );

  authRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginRedirect),
        tap((action) => this.router.navigate(['/home']))
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap((action) =>
        this.authService
          .resetPassword(action.email)
          .then(() => {
            return AuthActions.resetPasswordSuccess();
          })
          .catch((err) => {
            return AuthActions.resetPasswordFail({ error: err });
          })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}
}
