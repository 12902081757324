<div class="container successo-prenotazione">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3 text-center">
      <img
        src="./assets/images/flusso_prenotazione/success-payment.png"
        alt="successo prenotazione"
        class="img-fluid"
      />

      <p>Hai prenotato con successo!</p>
      <p>A breve riceverai un’email di conferma dal nostro <strong>Health Manager</strong>. </p>

      <button class="do3-secondary-btn drfeel-bg" (click)="toRichieste()">
        Vai alla lista appuntamenti
      </button>
    </div>
  </div>
</div>
