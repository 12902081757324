import { Action, createReducer, on } from '@ngrx/store';
import { UserData } from 'src/app/model/UserData';
import * as AuthActions from './auth.actions';

export interface AuthState {
  userData: UserData | null;
  loggedIn: boolean;
  error: boolean;
  errorMessage: any;
  isLoading: boolean;
  wrongCoupon: boolean;
  resetSent: boolean;
  resetError: boolean;
}

export const initialState: AuthState = {
  userData: null,
  loggedIn: false,
  error: false,
  errorMessage: '',
  isLoading: false,
  wrongCoupon: false,
  resetSent: false,
  resetError: false,
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.login, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    userData: action.userData,
    loggedIn: true,
    error: false,
    errorMessage: '',
    isLoading: false,
  })),

  on(AuthActions.loginFailed, (state, action) => ({
    ...state,
    userData: null,
    loggedIn: false,
    error: true,
    errorMessage:
      "Impossibile eseguire l'accesso. Controllare le credenziali inserite e riprovare",
    isLoading: false,
  })),

  on(AuthActions.logout, (state, action) => ({
    ...state,
    userData: null,
    loggedIn: false,
    error: false,
    errorMessage: '',
    isLoading: false,
  })),

  on(AuthActions.register, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(AuthActions.registerSuccess, (state, action) => ({
    ...state,
    // userData: action.userData,
    loggedIn: true,
    error: false,
    isLoading: false,
    errorMessage: '',
  })),

  on(AuthActions.registerFail, (state, action) => ({
    ...state,
    userData: null,
    loggedIn: false,
    error: true,
    errorMessage: 'La registrazione non è andata a buon fine',
    isLoading: false,
  })),

  on(AuthActions.couponCheckFail, (state, action) => ({
    ...state,
    wrongCoupon: true,
    error: true,
    errorMessage: 'Il codice inserito non è valido',
  })),

  on(AuthActions.couponCheck, (state, action) => ({
    ...state,
    wrongCoupon: false,
    error: false,
    errorMessage: '',
  })),

  on(AuthActions.resetPassword, (state, action) => ({
    ...state,
    resetSent: false,
    resetError: false,
    isLoading: true,
  })),

  on(AuthActions.resetPasswordSuccess, (state, action) => ({
    ...state,
    resetSent: true,
    resetError: false,
    isLoading: false,
  })),

  on(AuthActions.resetPasswordFail, (state, action) => ({
    ...state,
    resetSent: false,
    resetError: true,
    isLoading: false,
  }))
);

export function reducer(state: AuthState | undefined, action: Action): any {
  return authReducer(state, action);
}
