import { Injectable } from '@angular/core';
import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { setPersistence } from '@firebase/auth';
import { Store } from '@ngrx/store';
import { UserData } from 'src/app/model/UserData';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../store/auth.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private store: Store<fromApp.AppState>,
    private auth: Auth,
    private http: HttpClient
  ) {}
  private tokenExpirationTimer: any;
  public userData: UserData | null = null;

  login(email: string, password: string) {
    return setPersistence(this.auth, browserSessionPersistence)
      .then(() => signInWithEmailAndPassword(this.auth, email, password))
      .catch((err) => {
        console.error('Login error:', err);
        return err;
      });
  }

  async register(email: string, password: string) {
    await setPersistence(this.auth, browserSessionPersistence);
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((res) => {
        sendEmailVerification(res.user);
      })
      .catch((err) => {
        console.error('Register error:', err);
        return err;
      });
  }

  async resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        console.log('Password reset email sent');
      })
      .catch((err) => {
        console.error('Password reset error:', err);
        throw err;
      });
  }

  async checkCoupon(code: string, email: string) {
    return this.http
      .post<any>(environment.NEW_API + '/coupons/check', { code, email })
      .toPromise()
      .then((res) => {
        console.log('coupon check from service', res);
      })
      .catch((err) => {
        console.error('error coupon check from service', err);
        throw err;
      });
  }

  saveAuth(auth: any): void {
    // const token = auth.user.refreshToken;
    localStorage.setItem('userData', JSON.stringify(auth.userData));
  }

  getToken() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken();
        const userData: UserData = {
          uid: user.uid,
          email: user.email,
          refreshToken: user.refreshToken,
          accessToken: userToken,
        };
        // console.log('user from service:', user);
        this.userData = userData;
      }
    });
    return this.userData;
  }

  cleanAuth() {
    localStorage.removeItem('userData');
    return signOut(this.auth);
  }

  setLogoutTimer(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.store.dispatch(AuthActions.logout());
    }, expirationDuration);
  }

  clearLogoutTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
  }
}
