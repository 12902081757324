import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as MeActions from '../../core/header/store/me.actions';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeData } from 'src/app/model/Me';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
  @Input() userName: string | undefined = ' ';
  @Input() userAvatarUrl: string = './assets/images/AvatarM.webp';
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() fetchMe: boolean = true;
  @Input() fullName: string | undefined = ' ';

  meSubscription!: Subscription;
  public me!: MeData | null;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    if (this.fetchMe) {
      setTimeout(() => {
        this.store.dispatch(MeActions.fetchMe());
      }, 1000);
      this.meSubscription = this.store
        .select('me')
        .pipe(map((meState) => meState.me))
        .subscribe((me: MeData | null) => {
          this.me = me;
          if (me) {
            this.fullName = `${me.firstName} ${me.lastName}`;
            this.userAvatarUrl = me.email;
            this.userName = me.firstName;
          } else {
            this.userAvatarUrl = './assets/images/AvatarM.webp';
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.meSubscription) {
      this.meSubscription.unsubscribe();
    }
  }
}
