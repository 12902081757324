<div class="container richieste">
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

  <div class="row justify-content-center" *ngIf="!isOneActive && !isLoading">
    <h1 class="text-center do3-fw600 text-blue pt-5">
      Al momento non hai alcuna prenotazione in programma.<br />
      Contatta l’Health Manager per prenotare un nuovo Videoconsulto.
    </h1>
  </div>

  <div [ngSwitch]="viewMode">
    <div class="alert alert-danger alert-dismissible show d-flex align-items-center mt-5" role="alert" *ngIf="error">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
        <use xlink:href="#exclamation-triangle-fill" />
      </svg>
      <div>
        {{ errorMessage }}
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <app-loading-spinner class="row d-flex justify-content-center" *ngIf="isLoading"></app-loading-spinner>

    <div *ngIf="isOneActive && !isLoading">
      <div>
        <div class="d-md-none ml-2 pl-1">
          <h1 class="do3-lightblue-text do3-fw800">Appuntamenti</h1>
        </div>

        <div class="scroll-div">
          <!-- NEW  -->
          <div *ngFor="let richiesta of richieste?.data" class="richiesta-card">
            <div class="nuovo-header">
              <span class="do3-blue-text do3-fw800"> Data: </span>
              {{ richiesta.slot.from | date : "longDate" }} -
              <span class="do3-blue-text do3-fw800">orario: </span>
              {{ richiesta.slot.from | date : "shortTime" }}
            </div>

            <div class="">
              <div class="header-card row justify-content-between align-items-center do3-gray-text do3-fw600">
                <div class="img-profile-container medium big-border d-none d-lg-block col-12 col-md-1 ml-3"
                  (click)="onRichiestaClick(richiesta)">
                  <img class="img-profile pointer" [src]="getDoctorAvatar(richiesta)"
                    alt="{{ richiesta.doctor.name }}" />
                </div>
                <div *ngIf="richiesta.type === 'VIDEO_CONSULT'" class="col-12 col-md-4 text-dark">
                  <!-- <span class="do3-blue-text do3-fw800"> Medico: </span> -->
                  {{ richiesta.doctor.name }}
                </div>
                <div *ngIf="richiesta.type !== 'VIDEO_CONSULT'" class="col-12 col-md-5">
                  <span class="do3-blue-text do3-fw800">
                    Analisi in struttura:
                  </span>
                  Humanitas di Rozzano MI <br />
                  Via Alessandro Manzoni, 56
                </div>
                <div class="col-12 col-md-4 text-dark text-center">
                  <!-- <span class="do3-blue-text do3-fw800"> Specialistica: </span> -->
                  {{ richiesta.specialization.description }}
                </div>
                <div class="col-12 col-md-3 button-mobile text-center">
                  <div>
                    <button *ngIf="expireDate(richiesta.slot.from)" class="custom-deleted do3-blue-text do3-fw800"
                      type="button" disabled>
                      Cancella
                      <mat-icon class="red ms-1">delete</mat-icon>
                    </button>
                    <button class="do3-blue-text custom-deleted do3-fw800" *ngIf="!expireDate(richiesta.slot.from)"
                      type="button" data-bs-toggle="modal" data-bs-target="#modalDelete" data-id="{{ richiesta.id }}"
                      (click)="setRichiestaId(richiesta.id)">
                      Cancella
                      <mat-icon class="red ms-1">delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row justify-content-between align-items-center bottom-card">
                <div *ngIf="richiesta.type === 'VIDEO_CONSULT'"
                  class="shadow-box do3-blue-text do3-fw800 col-12 col-md">
                  <div class="d-flex align-items-center justify-content-center" *ngIf="
                      !richiesta.hasInformedConsent &&
                      richiesta.needInformedConsent
                    ">
                    <a *ngIf="expireQuestionario(richiesta.slot.from)" class="d-flex"
                      [href]="'/consenso/' + richiesta.id">
                      Consenso informato
                    </a>
                    <a *ngIf="!expireQuestionario(richiesta.slot.from)">Consenso informato</a>

                    <!--<mat-icon class="close"> circle </mat-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" class="ms-2">
                      <g id="Raggruppa_21035" data-name="Raggruppa 21035" transform="translate(-575 -210)">
                        <circle id="Ellisse_873" data-name="Ellisse 873" cx="9.5" cy="9.5" r="9.5"
                          transform="translate(575 210)" fill="#d00011" />
                        <line id="Linea_406" data-name="Linea 406" x2="12" transform="translate(578.5 219.5)"
                          fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5" />
                      </g>
                    </svg>
                  </div>

                  <div class="d-flex align-items-center justify-content-center" *ngIf="
                      richiesta.hasInformedConsent ||
                      !richiesta.needInformedConsent
                    ">
                    Consenso informato
                    <mat-icon class="done"> done </mat-icon>
                  </div>
                </div>
                <div class="shadow-box do3-blue-text do3-fw800 col-12 col-md" *ngIf="richiesta.needTriage">
                  <!-- <div class="d-flex align-items-center justify-content-center" *ngIf="!richiesta.needTriage">
                    Questionario
                  </div> -->
                  <!-- questionario completato -->
                  <div class="d-flex align-items-center justify-content-center"
                    *ngIf="richiesta.needTriage && richiesta.hasTriage">
                    Questionario
                    <mat-icon class="done"> done </mat-icon>
                  </div>
                  <!-- questionario non completato -->
                  <div class="d-flex align-items-center justify-content-center"
                    *ngIf="richiesta.needTriage && !richiesta.hasTriage">
                    <a *ngIf="expireQuestionario(richiesta.slot.from)" [href]="'/anamnesi/' + richiesta.id">
                      Questionario
                    </a>
                    <a *ngIf="!expireQuestionario(richiesta.slot.from)">
                      Questionario
                    </a>

                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" class="ms-2">
                      <g id="Raggruppa_21035" data-name="Raggruppa 21035" transform="translate(-575 -210)">
                        <circle id="Ellisse_873" data-name="Ellisse 873" cx="9.5" cy="9.5" r="9.5"
                          transform="translate(575 210)" fill="#d00011" />
                        <line id="Linea_406" data-name="Linea 406" x2="12" transform="translate(578.5 219.5)"
                          fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div *ngIf="richiesta.type === 'VIDEO_CONSULT'"
                  class="shadow-box do3-blue-text do3-fw800 col-12 col-md">
                  <div *ngIf="expireQuestionario(richiesta.slot.from)"  class="d-flex align-items-center justify-content-center" type="button" data-bs-toggle="modal"
                    data-bs-target="#documentiModal" (click)="openDocs(richiesta.id)">
                    Carica documenti
                    <mat-icon class="cloud"> cloud_upload </mat-icon>
                  </div>
                  <div *ngIf="!expireQuestionario(richiesta.slot.from)" class="d-flex align-items-center justify-content-center">
                    Carica documenti
                    <mat-icon class="cloud"> cloud_upload </mat-icon>
                  </div>
                </div>
                <div *ngIf="richiesta.type === 'VIDEO_CONSULT'" class="col-12 col-md text-end px-0">
                  <button type="button" class="do3-secondary-btn lightBlue mb-0 go-tele"
                    (click)="handleDetailClick(richiesta)" [disabled]="
                      (!richiesta.hasInformedConsent &&
                        richiesta.needInformedConsent) ||
                      (!richiesta.hasTriage && richiesta.needTriage)
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.94" height="23.501" viewBox="0 0 23.94 23.501"
                      class="me-1">
                      <g id="Raggruppa_1376" data-name="Raggruppa 1376" transform="translate(0.25 0.25)">
                        <path id="Tracciato_1791" data-name="Tracciato 1791"
                          d="M22.019,19.74H1.421A1.422,1.422,0,0,1,0,18.319V2.021A1.422,1.422,0,0,1,1.421.6h20.6A1.422,1.422,0,0,1,23.44,2.021v16.3A1.422,1.422,0,0,1,22.019,19.74ZM1.421,1.311a.711.711,0,0,0-.711.711v16.3a.711.711,0,0,0,.711.711h20.6a.711.711,0,0,0,.711-.711V2.021a.711.711,0,0,0-.711-.711Z"
                          transform="translate(0 -0.6)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                        <path id="Tracciato_1792" data-name="Tracciato 1792"
                          d="M23.183,20.169H5.771A1.422,1.422,0,0,1,4.35,18.748V6.391A1.422,1.422,0,0,1,5.771,4.97H23.183A1.422,1.422,0,0,1,24.6,6.391V18.748A1.422,1.422,0,0,1,23.183,20.169ZM5.771,5.681a.711.711,0,0,0-.711.711V18.748a.711.711,0,0,0,.711.711H23.183a.711.711,0,0,0,.711-.711V6.391a.711.711,0,0,0-.711-.711Zm6.651,18.792a.358.358,0,0,1-.322-.2l-.941-1.963A.355.355,0,1,1,11.8,22l.941,1.963a.353.353,0,0,1-.168.472A.346.346,0,0,1,12.422,24.473Zm4.109,0a.339.339,0,0,1-.154-.037.356.356,0,0,1-.168-.472L17.15,22a.355.355,0,1,1,.641.3l-.941,1.963A.346.346,0,0,1,16.532,24.473Z"
                          transform="translate(-2.757 -3.369)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                        <path id="Tracciato_1793" data-name="Tracciato 1793"
                          d="M28.119,58.894H19.175a.353.353,0,0,1-.355-.355,2.248,2.248,0,0,1,2.245-2.249h5.171a2.248,2.248,0,0,1,2.245,2.245A.37.37,0,0,1,28.119,58.894Zm-8.552-.711h8.156A1.534,1.534,0,0,0,26.233,57H21.061A1.539,1.539,0,0,0,19.567,58.184Z"
                          transform="translate(-11.927 -35.893)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                        <g id="Raggruppa_1369" data-name="Raggruppa 1369" transform="translate(9.936 17.258)">
                          <path id="Tracciato_1794" data-name="Tracciato 1794"
                            d="M30.342,48.431H27.485a.355.355,0,0,1,0-.711h2.853a.355.355,0,0,1,0,.711Z"
                            transform="translate(-27.13 -47.72)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                        </g>
                        <g id="Raggruppa_1370" data-name="Raggruppa 1370" transform="translate(6.673 3.586)">
                          <path id="Tracciato_1795" data-name="Tracciato 1795"
                            d="M27.959,21.616H18.575a.353.353,0,0,1-.355-.355,5.047,5.047,0,0,1,10.094,0A.353.353,0,0,1,27.959,21.616Zm-9.013-.711h8.644a4.336,4.336,0,0,0-8.644,0Zm4.322-5.578a2.469,2.469,0,1,1,2.469-2.469A2.472,2.472,0,0,1,23.267,15.327Zm0-4.227a1.758,1.758,0,1,0,1.758,1.758A1.761,1.761,0,0,0,23.267,11.1Z"
                            transform="translate(-18.22 -10.39)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                        </g>
                      </g>
                    </svg>
                    Collegati
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="material-icons-outlined mb-4">delete</span>
        <p>Confermi di voler eliminare l'appuntamento?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn light-blue text-white text-uppercase" data-bs-dismiss="modal">
          Annulla
        </button>
        <button type="button" class="btn blue text-uppercase text-white" (click)="deleteRichiesta()"
          data-bs-dismiss="modal">
          Conferma
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Upload Documents-->
<div class="modal fade" id="documentiModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-lg-down modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button #modal2 type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div>
          <h1 class="text-center mb-4 do3-fw800 do3-blue-text">
            Qui puoi allegare documenti che vuoi condividere con il tuo dottore.
          </h1>
        </div>

        <!-- Two columns with a button to the left and a list to the right -->
        <div class="container">
          <div class="row mx-auto">
            <div class="col-md-6 col-12 right-border d-flex justify-content-center mobile-1 altezza">
              <div class="m-3">
                <div class="mb-3">
                  <h2 class="do3-blue-text do3-fw800">
                    Seleziona un documento dal tuo pc
                  </h2>
                  <div class="d-flex justify-content-center mb-3">
                    <div>
                      <label for="tipoDoc">Scegli il tipo di documento:</label>
                      <select name="tipoDoc" id="tipoDoc" [(ngModel)]="typeDocSelected" (change)="onOptionSelected()">
                        <option value=""></option>
                        <option value="diagnosis">Referti</option>
                        <option value="prescription">Prescrizioni</option>
                        <option value="analysis">Esami</option>
                        <option value="report">Immagini</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div>
                      <div *ngIf="typeDocSelected.length == 0" class="do3-secondary-btn light-blue false-btn">
                        Carica Documento
                      </div>
                    </div>
                    <div *ngIf="typeDocSelected.length != 0">
                      <app-upload-doc [typeDocSelected]="typeDocSelected" (failModal)="onFailModal()" (successModal)="onSuccessModal()"
                       (closeModal)="onCloseModal()"
                        [isInTelevisita]="true" [isRichiesta]="true" [richiestaId]="richiestaId">Carica
                        Documento</app-upload-doc>
                    </div>
                  </div>
                </div>

                <h2 *ngIf="typeDocSelected.length > 0 && filtredDocs.length > 0" class="do3-blue-text do3-fw800">
                  -
                  <span *ngIf="typeDocSelected === 'diagnosis'">Referti già caricati:</span>
                  <span *ngIf="typeDocSelected === 'prescription'">Prescrizioni già caricate:</span>
                  <span *ngIf="typeDocSelected === 'analysis'">Esami già caricati:</span>
                  <span *ngIf="typeDocSelected === 'report'">Immagini già caricate:</span>
                </h2>
                <div class="second-container">
                  <!-- qui scroll solo su documenti -->
                  <div *ngFor="let doc of filtredDocs">
                    <div *ngIf="!isDocShared(doc.id)">
                      <div class="d-flex align-items-center single-doc" (click)="linkDocIdToRichiesta(doc.id)">
                        <!-- <mat-icon class="do3-lightblue-text link-document">
                          description
                        </mat-icon> -->
                        <ng-container *ngIf="doc.author.type === 'patient'">
                          <svg class="doc-svg" xmlns="http://www.w3.org/2000/svg" width="19.493" height="22.7"
                            viewBox="0 0 19.493 22.7">
                            <g id="user" transform="translate(0.85 0.85)">
                              <g id="Raggruppa_21448" data-name="Raggruppa 21448" transform="translate(3.983)">
                                <g id="Raggruppa_21447" data-name="Raggruppa 21447">
                                  <path id="Tracciato_85468" data-name="Tracciato 85468"
                                    d="M115.506,0a4.914,4.914,0,1,0,4.914,4.914A4.912,4.912,0,0,0,115.506,0Z"
                                    transform="translate(-110.592)" fill="none" stroke="#ff3d77" stroke-width="1.7" />
                                </g>
                              </g>
                              <g id="Raggruppa_21450" data-name="Raggruppa 21450" transform="translate(0 10.397)">
                                <g id="Raggruppa_21449" data-name="Raggruppa 21449">
                                  <path id="Tracciato_85469" data-name="Tracciato 85469"
                                    d="M42.554,205.824H38.727a6.987,6.987,0,0,0-6.983,6.983v2.845a.762.762,0,0,0,.776.776H48.761a.762.762,0,0,0,.776-.776v-2.845A6.987,6.987,0,0,0,42.554,205.824Z"
                                    transform="translate(-31.744 -205.824)" fill="none" stroke="#ff3d77"
                                    stroke-width="1.7" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </ng-container>
                        <ng-container *ngIf="doc.author.type === 'doctor'">
                          <svg class="doc-svg" xmlns="http://www.w3.org/2000/svg" width="21.632" height="21.5"
                            viewBox="0 0 21.632 21.5">
                            <path id="stethoscope"
                              d="M39.554,31.731a2.218,2.218,0,1,0-2.216-2.218A2.222,2.222,0,0,0,39.554,31.731Zm1.126-2.217a1.125,1.125,0,1,1-1.126-1.125A1.127,1.127,0,0,1,40.681,29.513Zm-1.126.594a.594.594,0,1,0-.594-.594A.6.6,0,0,0,39.554,30.108Zm-2.636.175a2.739,2.739,0,0,1,0-1.54H35.124a3.513,3.513,0,0,0-3.509,3.509v1.908a1.969,1.969,0,0,1-3.938,0V30.051a.266.266,0,0,1,.216-.26,4.655,4.655,0,0,0,3.616-3.385l.654-2.414-1.09-.3-.654,2.414a3.54,3.54,0,0,1-3.451,2.639h-.06a3.538,3.538,0,0,1-3.451-2.639L22.8,23.7l-1.092.3.655,2.414a4.661,4.661,0,0,0,3.56,3.374.264.264,0,0,1,.21.26v4.121a3.51,3.51,0,0,0,7.02,0V32.252a1.97,1.97,0,0,1,1.968-1.969Zm-5.407-7.018L32,23.4l1.175-4.333a1.423,1.423,0,0,0-1.375-1.8h-.855v.5H31.8a.919.919,0,0,1,.887,1.16Zm-9.149,0-1.175-4.333a.919.919,0,0,1,.887-1.16h.855v-.5h-.855a1.423,1.423,0,0,0-1.375,1.8L21.875,23.4Zm1.191-4.895h.755a.85.85,0,1,0,0-1.7h-.755a.1.1,0,0,0-.094.094v1.512A.1.1,0,0,0,23.553,18.371Zm5.16-.85a.852.852,0,0,0,.851.85h.755a.1.1,0,0,0,.094-.094V16.764a.1.1,0,0,0-.094-.094h-.755A.853.853,0,0,0,28.714,17.521Z"
                              transform="translate(-20.393 -16.42)" fill="#ff3d77" stroke="#ff3d77" stroke-width="0.5"
                              fill-rule="evenodd" />
                          </svg>
                        </ng-container>

                        <div class="file-name">
                          {{ doc.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 d-flex justify-content-center mobile-2">
              <div class="mt-3" *ngIf="documentsFromStore">
                <div *ngIf="!documentsFromStore.length">
                  <h2 class="text-center do3-fw600 text-blue pt-5">
                    Al momento non ci sono documenti allegati all'appuntamento.
                  </h2>
                </div>
                <div *ngIf="documentsFromStore.length">
                  <h2 class="do3-blue-text do3-fw800">
                    Documenti già allegati all'appuntamento:
                  </h2>
                  <div class="w-90">
                    <div class="d-flex align-items-center" *ngFor="let item of documentsFromStore">
                      <mat-icon class="do3-lightblue-text link-document">
                        task
                      </mat-icon>
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #modalPacchetto type="button" data-bs-toggle="modal" data-bs-target="#modalPacchetto" [hidden]="true">
  Pagamento
</button>

<!-- Button trigger modal -->
<button #openModalSuccess data-bs-toggle="modal" data-bs-target="#exampleModal3"></button>


<!-- Modal -->
<div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-end">
        <button type="button" class="p-3 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0 d-flex justify-content-center">
        <h2>
          <div>Documento caricato correttamente</div>
        </h2>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<button type="button" #modalerror data-bs-toggle="modal" data-bs-target="#prova3"></button>

<!-- Modal -->
<div class="modal fade" id="prova3" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-end">
        <button type="button" class="p-3 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0 d-flex justify-content-center">
        <h2>
          <div>Impossibile caricare il documento al momento</div>
        </h2>
      </div>
    </div>
  </div>
</div>
