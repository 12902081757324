<div id="prossimo">
  <div class="d-flex align-items-center">
    <div class="m-3">
      <div class="img-profile-container large d-none d-lg-block col-1 ml-3">
        <img
          *ngIf="richiesta"
          class="img-profile pointer"
          [src]="getDoctorAvatar()"
        />
        <img
          *ngIf="!richiesta"
          class="img-profile pointer"
          src="https://pazdev.dottorionline.com/api/doctors/39x32182/image"
        />
      </div>
    </div>
    <div class="flex-grow-1" *ngIf="richiesta">
      <h1><strong>Il tuo prossimo appuntamento</strong></h1>
      <h1>
        Videoconsulto con
          <span *ngIf="richiesta.doctor.gender === 'MALE' " class="m-0">il <strong>Dott.</strong></span>
          <span *ngIf="richiesta.doctor.gender === 'FEMALE'" class="m-0">la <strong>Dott.ssa</strong></span>
          <strong>
            {{ richiesta.doctor.name }}
          </strong>
      </h1>
      <button class="custom-btn" (click)="goAppuntamenti()">
        I tuoi appuntamenti
      </button>
    </div>
    <div class="flex-grow-1" *ngIf="richiesta == undefined">
      <h1><strong>Incontra il tuo medico</strong></h1>
      <h1>Prenota un videoconsulto con il <strong>Dott. Natale</strong></h1>
      <button class="custom-btn">
        <a
          href="prenotazione?step=calendario&docId=39x32182&specId=38x4712"
          class="text-decoration-none text-reset"
          >Prenota
        </a>
      </button>
    </div>
    <div class="m-3" *ngIf="richiesta">
      <div class="d-flex align-items-center justify-content-center">
        <h1 class="white-box">
          {{ richiesta.slot.from | date : "dd" }}
          <!-- 25 -->
        </h1>
      </div>
      <div class="text">
        {{ richiesta.slot.from | date : "MMMM" }} <br />
        ore {{ richiesta.slot.from | date : "shortTime" }}
      </div>
    </div>

    <div class="m-3 align-self-start" *ngIf="richiesta">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.777"
        height="18.584"
        viewBox="0 0 23.777 18.584"
      >
        <g
          id="Raggruppa_21610"
          data-name="Raggruppa 21610"
          transform="translate(-1180 -209.441)"
        >
          <path
            id="Tracciato_85435"
            data-name="Tracciato 85435"
            d="M123.795,111.872a8.2,8.2,0,0,1-.489-2.79v-2.447a4.626,4.626,0,0,0-1.5-3.39,5.3,5.3,0,0,0-3.629-1.4,4.972,4.972,0,0,0-5.133,4.795v2.447a8.2,8.2,0,0,1-.489,2.79l-1.226,3.384a.807.807,0,0,0,.759,1.082h12.178a.807.807,0,0,0,.759-1.082Z"
            transform="translate(1073.715 109.547)"
            fill="#fff"
          />
          <circle
            id="Ellisse_904"
            data-name="Ellisse 904"
            cx="0.973"
            cy="0.973"
            r="0.973"
            transform="translate(1190.915 209.441)"
            fill="#fff"
          />
          <path
            id="Tracciato_85436"
            data-name="Tracciato 85436"
            d="M73.085,192.338A2.14,2.14,0,0,0,75,191.151H71.167A2.14,2.14,0,0,0,73.085,192.338Zm-7.12-12.814a.477.477,0,0,0-.674.023,5.185,5.185,0,0,0,0,7,.477.477,0,0,0,.7-.651,4.233,4.233,0,0,1,0-5.7A.476.476,0,0,0,65.964,179.524Z"
            transform="translate(1118.803 35.688)"
            fill="#fff"
          />
          <path
            id="Tracciato_85437"
            data-name="Tracciato 85437"
            d="M9.279,142.247a.477.477,0,1,0-.7-.651,7.868,7.868,0,0,0,0,10.616.477.477,0,1,0,.7-.651A6.921,6.921,0,0,1,9.279,142.247ZM26.2,143.4a.477.477,0,0,0-.7.651,4.234,4.234,0,0,1,0,5.7.477.477,0,1,0,.7.651,5.185,5.185,0,0,0,0-7Z"
            transform="translate(1173.477 71.83)"
            fill="#fff"
          />
          <path
            id="Tracciato_85438"
            data-name="Tracciato 85438"
            d="M445.768,141.6a.477.477,0,1,0-.7.651,6.921,6.921,0,0,1,0,9.314.477.477,0,0,0,.7.651,7.868,7.868,0,0,0,0-10.615Z"
            transform="translate(755.948 71.83)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  </div>
</div>
