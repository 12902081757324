import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as RichiesteActions from '../richieste/store/richieste.actions';
import * as fromApp from '../../store/app.reducer';
import { fetchPolicies } from '../prenotazione/store/actions/prenotazione.action';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private richiesteSubscription: Subscription;
  private orderSubscription: Subscription;
  private policyStoreSubscription: Subscription;
  private profileSubscription: Subscription;
  public documentsFromStore: ProfiloDocumentDatum[];
  public lastReferto: any;
  public lastPrescrizione: any;
  public lastAnalisi: any;
  public nextAppuntamento: RichiestaDatum;
  public nomeUtente: string = '';
  public meSubscription: Subscription;

  public richieste: Richiesta | null;
  public isLoading: boolean = true;

  public error: boolean = false;
  public errorMessage: string = '';

  public ordersToPay: any[] = [];
  private refresh = false;

  @Output() changeStep = new EventEmitter<string>();
  @Output() doctype = new EventEmitter<string>();

  receivedMessage: string;

  @ViewChild('modalPacchetto') modalPacchetto: ElementRef;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  receiveStep(message: string) {
    this.receivedMessage = message;
    this.changeStep.emit('richieste');
  }

  ngOnInit(): void {
    this.store.dispatch(RichiesteActions.fetchOrders());
    this.store.dispatch(ProfileActions.fetchDocuments());
    this.store.dispatch(ProfileActions.allCoupons());
    this.store.dispatch(
      RichiesteActions.fetchRequests({
        searchRequest: true,
        statusValue: 'OPEN',
        fromDate: this.getHumanDate(new Date()),
        toDate: this.getHumanDate(new Date(), true, false),
      })
    );
    setTimeout(() => {
      this.store.dispatch(fetchPolicies({ options: false }));
    }, 3000);
    this.policyStoreSubscription = this.store
      .select('prenotazione')
      .subscribe((prenotazione) => {
        if (prenotazione.policies && prenotazione.policies.data.length !== 0) {
          this.router.navigate(['/policy']);
        }
      });

    this.orderSubscription = this.store
      .select('richieste')
      .pipe(map((state) => state.allOrders))
      .subscribe((orders) => {
        this.ordersToPay =
          orders && orders.length > 0
            ? orders.filter((order) => order.paymentInfo?.status === 'NEW')
            : [];
        if (this.ordersToPay.length > 0 && !this.refresh) {
          this.refresh = true;
          this.modalPacchetto.nativeElement.click();
        }
      });
    this.profileSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.documentsFromStore = profile.documents;
        this.lastPrescrizione =
          this.documentsFromStore
            .filter((doc) => doc.documentType === 'PRESCRIPTION')
            .shift() || undefined;
        this.lastReferto =
          this.documentsFromStore
            .filter((doc) => doc.documentType === 'DIAGNOSIS')
            .shift() || undefined;
        this.lastAnalisi =
          this.documentsFromStore
            .filter((doc) => doc.documentType === 'ANALYSIS')
            .shift() || undefined;
      });
    this.meSubscription = this.store
      .select('me')
      .pipe(map((meState) => meState.me))
      .subscribe((me) => {
        this.nomeUtente = me?.firstName || '';
      });
    this.richiesteSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        this.richieste = richieste.requests;

        if (this.richieste && this.richieste.data) {
          const dataOraAttuali = new Date();
          const appuntamentiFuturi: RichiestaDatum[] = []; // Array per salvare gli appuntamenti futuri

          this.richieste.data.forEach((appuntamento) => {
            let dataOraAppuntamento = new Date(appuntamento.slot.to);
            if (
              dataOraAttuali < dataOraAppuntamento &&
              appuntamento.type === 'VIDEO_CONSULT'
            ) {
              appuntamentiFuturi.push(appuntamento); // Aggiungi l'appuntamento all'array
            }
          });

          // Ora l'array appuntamentiFuturi contiene tutti gli appuntamenti futuri che soddisfano le condizioni
          this.nextAppuntamento = appuntamentiFuturi[0]; // Assegna il primo appuntamento all'attributo nextAppuntamento
        }

        this.isLoading = richieste.loading;
        this.error = richieste.error;
        this.errorMessage = richieste.errorMessage;
      });
  }

  ngOnDestroy(): void {
    this.richiesteSubscription && this.richiesteSubscription.unsubscribe();
    this.policyStoreSubscription && this.policyStoreSubscription.unsubscribe();
    this.orderSubscription && this.orderSubscription.unsubscribe();
    this.profileSubscription && this.profileSubscription.unsubscribe();
    this.meSubscription && this.meSubscription.unsubscribe();
  }

  getHumanDate = (date: any, nextYear = false, nextDay = false) => {
    const dd = nextDay
      ? String(date.getDate() + 1).padStart(2, '0')
      : String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const yyyy = nextYear ? date.getFullYear() + 1 : date.getFullYear();
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');
    const humanDate = `${yyyy}-${mm}-${dd}T${hh}:${min}:${sec}`;
    return humanDate;
  };

  goToDoc(string: string) {
    console.log(string);
    this.doctype.emit(string);
  }
}
