import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RichiesteRoutingModule } from './richieste-routing.module';
import { RichiesteComponent } from './components/richieste.component';
import { SharedModule } from '../../shared/shared.module';
import { AgendaModule } from '../agenda/agenda.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [RichiesteComponent],
  imports: [
    CommonModule,
    RichiesteRoutingModule,
    SharedModule,
    AgendaModule,
    MatIconModule,
    FormsModule
  ],
  exports: [RichiesteComponent]
})
export class RichiesteModule { }
