<div class="container anamnesi my-5">

  <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>

  <div class="col d-flex align-items-center">
    <button class="do3-back-btn col-12 p-0" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      Torna indietro
    </button>
  </div>

  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <ng-container *ngIf="!loading">
    <div class="scroll-div">
      <div class="d-md-none">
        <h1 class="do3-lightblue-text do3-fw800">Questionario</h1>
      </div>
      <div *ngIf="richieste.length > 0">
        <div class="row do3-fw800 do3-blue-text header d-none d-md-flex">
          <div class="col-3">Dottore</div>

          <div class="col-3">Tipologia</div>
          <div class="col-3">ID Appuntamento</div>
          <div class="col-3">Data</div>
        </div>
        <ng-container *ngFor="let richiesta of richieste; let i = index">
          <app-single-anamnesi [richiesta]="richiesta"></app-single-anamnesi>
        </ng-container>
      </div>
      <ng-container *ngIf="richieste.length == 0">
        <h1 class="text-center do3-fw600 text-blue pt-5">
          Al momento non hai nessun Questionario in archivio.
        </h1>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="richiesteMeta && richiesteMeta.pagination.totalPages > 0"
  >
    <!-- Pagination -->
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="pagination">
          <a
            class="page-link"
            (click)="handlePageClick(0)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">first_page</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page - 1)"
            [ngClass]="{
              disabled: page === 0
            }"
          >
            <span class="material-icons-outlined">chevron_left</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(page + 1)"
            [ngClass]="{
              disabled: page === richiesteMeta.pagination.totalPages - 1
            }"
          >
            <span class="material-icons-outlined">chevron_right</span>
          </a>
          <a
            class="page-link"
            (click)="handlePageClick(richiesteMeta.pagination.totalPages - 1)"
            [ngClass]="{
              disabled: page === richiesteMeta.pagination.totalPages - 1
            }"
          >
            <span class="material-icons-outlined">last_page</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
