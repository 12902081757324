import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prossimo-consulto',
  templateUrl: './prossimo-consulto.component.html',
  styleUrls: ['./prossimo-consulto.component.scss']
})
export class ProssimoConsultoComponent implements OnInit {
  @Output() changeStep = new EventEmitter<string>();
  @Input() richiesta: any;
  constructor() { }

  ngOnInit(): void {
    console.log(this.richiesta)
  }

  goAppuntamenti() {
    this.changeStep.emit('richieste');
  }

  getDoctorAvatar() {
    return `${environment.NEW_API}/doctors/${this.richiesta.doctor.id}/image`;
  }

}
