<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div id="more-percorsi" *ngIf="!loading">
  <h2 class="fw-bold title">Scopri i nostri percorsi</h2>
  <div class="row row-cols-md-3 g-md-3">
    <!-- Vivi meglio con link -->
    <!-- <div class="col-md-4 singolo-percorso">
      <a routerLink="/home/percorsi/1" class="d-flex position-relative">
        <img
          src="./assets/images/vivimeglio.png"
          class="img-fluid"
          alt="vivi meglio"
        />
        <div
          class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
        >
          <h2>Vivi Meglio</h2>
          <h3>Prevenzione e Benessere</h3>
        </div>
      </a>
    </div> -->

    <!-- Vivi meglio senza link coming soon -->
    <div class="col-md-4 singolo-percorso">
      <div class="position-relative">
        <img
          src="./assets/images/vivimeglio.png"
          class="img-fluid"
          alt="mind your health"
        />
        <div
          class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
        >
          <h2>Vivi Meglio</h2>
          <h3>Prevenzione e Benessere</h3>
        </div>
        <div
          class="position-absolute h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center overlay"
        >
          <span>Coming Soon</span>
        </div>
      </div>
    </div>
    <!-- Fine vivi meglio senza link coming soon -->
    <div class="col-md-4 singolo-percorso">
      <div class="position-relative">
        <img
          src="./assets/images/mindyourhealth.png"
          class="img-fluid"
          alt="mind your health"
        />
        <div
          class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
        >
          <h2>Mind your health</h2>
          <h3>Mental Health</h3>
        </div>
        <div
          class="position-absolute h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center overlay"
        >
          <span>Coming Soon</span>
        </div>
      </div>
    </div>
    <div class="col-md-4 singolo-percorso">
      <div class="position-relative">
        <img
          src="./assets/images/dna.png"
          class="img-fluid"
          alt="nutrigenomica"
        />
        <div
          class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
        >
          <h2>Alimenta il DNA</h2>
          <h3>Nutrigenomica</h3>
        </div>
        <div
          class="position-absolute h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center overlay"
        >
          <span>Coming Soon</span>
        </div>
      </div>
    </div>
  </div>
</div>
