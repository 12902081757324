import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { IsLoggedInDirective } from './is-logged-in.directive';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UIModule } from './UI/ui.module';
import { TopperInfoDoctorComponent } from './topper-info-doctor/topper-info-doctor.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { DatiProfiloComponent } from './dati-profilo/dati-profilo.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';
import { MovergyComponent } from './movergy/movergy.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { GetStreamComponent } from './get-stream/get-stream.component';
import {
  StreamAutocompleteTextareaModule,
  StreamChatModule,
} from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    IsLoggedInDirective,
    UserAvatarComponent,
    UserInfoComponent,
    TopperInfoDoctorComponent,
    UploadDocComponent,
    ChangeAvatarComponent,
    DatiProfiloComponent,
    MovergyComponent,
    WorkInProgressComponent,
    GetStreamComponent,
  ],
  imports: [
    CommonModule,
    UIModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    AvatarModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    StreamChatModule,
    StreamAutocompleteTextareaModule,
  ],
  exports: [
    LoadingSpinnerComponent,
    IsLoggedInDirective,
    UserAvatarComponent,
    UserInfoComponent,
    UIModule,
    TopperInfoDoctorComponent,
    AvatarModule,
    UploadDocComponent,
    ChangeAvatarComponent,
    DatiProfiloComponent,
    MovergyComponent,
    GetStreamComponent,
  ],
})
export class SharedModule {}
