import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import * as RichiesteActions from 'src/app/features/richieste/store/richieste.actions';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss'],
})
export class UploadDocComponent implements OnInit {
  @Input() isRichiesta: boolean;
  @Input() richiestaId: string = '';
  @Input() isInTelevisita: boolean = false;
  @Input() typeDocSelected: string;
  public success: boolean = false;
  public fail: boolean = false;
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('openModalSuccess', { static: false }) openModalSuccess: ElementRef;
  @ViewChild('modalerror', { static: true }) modalerror: ElementRef;

  files = [];
  private postUrl: string = environment.NEW_API + '/documents';
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() failModal: EventEmitter<any> = new EventEmitter();
  @Output() successModal: EventEmitter<any> = new EventEmitter();


  constructor(
    private httpClient: HttpClient,
    private store: Store<fromApp.AppState>
  ) { }

  ngOnInit(): void { }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('document', file.data, file.data.name);
    if (this.typeDocSelected) {
      formData.append('documentType', this.typeDocSelected.toUpperCase());
    }
    if (this.isRichiesta) {
      formData.append('requestId', this.richiestaId);
    }
    console.log('formData', formData);
    if (this.isInTelevisita) {
      this.closeModal.emit();
    }
    return this.httpClient
      .post(this.postUrl, formData, this.payload)
      .subscribe((data) => {
        console.log('success', data);
        if (this.isRichiesta) {
          this.successModal.emit();
          this.store.dispatch(
            RichiesteActions.fetchRichiestaDocuments({
              richiestaId: this.richiestaId,
            })
          );
        } else {
          this.openModalSuccess.nativeElement.click();
          this.store.dispatch(ProfileActions.fetchDocuments());
        }
      },
        (error) => {
          this.failModal.emit();
          this.modalerror.nativeElement.click();
          console.log(error)
        });

  }
  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadFile(fileParams);
    };
    fileUpload.click();

  }
}
