import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { fetchSurvey } from '../../richieste/store/richieste.actions';
import * as fromApp from '../../../store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { Richiesta } from 'src/app/model/Richiesta';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
declare let bootstrap: any

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tooltipElement') tooltipElement!: ElementRef;
  private storeSub: Subscription;
  public surveys: Richiesta | null = null;
  public loading: boolean = true;
  public kpiUrl: any;
  private triageCount: number;
  public allTriage: boolean;

  constructor(
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngAfterViewInit() {
    this.initTooltip();
  }

  private initTooltip() {
    const tooltipTriggerList = [].slice.call(this.tooltipElement.nativeElement.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl: any) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.store.dispatch(fetchSurvey());
      this.storeSub = this.store.select('richieste').subscribe((state) => {
        this.surveys = state.surveys;
        this.loading = state.loading;
        console.log('surveys', this.surveys);
        if (this.surveys !== null) {
          this.triageCount = this.surveys.data.filter(
            (datum) => datum.hasTriage
          ).length;
          this.allTriage = this.surveys.data.length === this.triageCount;
        }
      });
      this.getKPI();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.storeSub && this.storeSub.unsubscribe();
  }

  handleType(id: string | undefined) {
    switch (id) {
      case 'KPI_Q1':
        return 'health';
      case 'KPI_Q2':
        return 'nutrition';
      case 'KPI_Q3':
        return 'mind';
      case 'KPI_Q4':
        return 'movement';
      default:
        return '';
    }
  }

  getKPI() {
    return this.http
      .get(`${environment.NEW_API}/profile/kpi`, { responseType: 'blob' })
      .pipe(
        catchError((err) => {
          this.kpiUrl = null;
          console.error('No Health Meter from HM');
          return of(null);
        })
      )
      .subscribe((data: any) => {
        if (data !== null) {
          return this.createImageFromBlob(data);
        }
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.kpiUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
