<section>
  <div class="info">
    <div class="d-flex flex-column align-items-end me-2">
      <h2 class="info-name mb-0" [class]="size">
        {{ userName }}
      </h2>
      <small class="do3-gray-text ml-1"
        >Completamento profilo {{ me?.completionPercentage }}%</small
      >
    </div>
    <app-user-avatar
      [fullName]="fullName"
      class="info-avatar"
      [me]="me"
      [size]="size"
    >
    </app-user-avatar>
  </div>
</section>
