<!-- Chat icon mobile-->
<div
  class="fixed bottom-0 right-0 mb-8 me-0 md:hidden z-50 w-[80px] rounded-tl-full rounded-bl-full bg-white items-center justify-center shadow-md"
>
  <a
    href="https://wa.me/393757347706"
    class="text-drfeel px-4 py-3 md:py-4 md:px-8 flex items-center w-full"
    target="_blank"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.779 28.779"
      class="h-9 pr-3"
    >
      <path
        id="Icon_ionic-logo-whatsapp"
        data-name="Icon ionic-logo-whatsapp"
        d="M16.9,2.25A14.074,14.074,0,0,0,2.773,16.269,13.874,13.874,0,0,0,4.8,23.508L2.25,31.029l7.822-2.485A14.152,14.152,0,0,0,31.029,16.269,14.074,14.074,0,0,0,16.9,2.25Zm7.025,19.343a3.649,3.649,0,0,1-2.5,1.611c-.663.035-.681.514-4.294-1.056s-5.785-5.386-5.956-5.632a6.929,6.929,0,0,1-1.333-3.755,4,4,0,0,1,1.375-2.937,1.384,1.384,0,0,1,.978-.412c.284,0,.469-.008.679,0s.526-.044.8.683.928,2.515,1.012,2.7a.655.655,0,0,1,.007.628,2.453,2.453,0,0,1-.383.583c-.189.2-.4.452-.565.607-.188.171-.384.358-.186.724a10.811,10.811,0,0,0,1.913,2.553,9.857,9.857,0,0,0,2.833,1.883c.354.193.566.172.785-.06s.94-1.012,1.195-1.36.492-.281.819-.15,2.066,1.064,2.42,1.256.591.291.675.443A2.967,2.967,0,0,1,23.926,21.593Z"
        transform="translate(-2.25 -2.25)"
        fill="#FF3D77"
      />
    </svg>
  </a>
</div>
<!-- Chat icon desktop-->
<div
  id="form-contact"
  class="hidden md:block fixed bottom-0 right-0 mb-4 z-50 w-[350px] bg-white me-0"
>
  <div id="title-chat" class="lg:flex items-center justify-center">
    <a
      class="text-drfeel px-4 py-3 md:px-8 flex items-center w-full d-flex justify-center"
      target="_blank"
      href="https://wa.me/393757347706"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28.779 28.779"
        class="h-9 pr-3"
      >
        <path
          id="Icon_ionic-logo-whatsapp"
          data-name="Icon ionic-logo-whatsapp"
          d="M16.9,2.25A14.074,14.074,0,0,0,2.773,16.269,13.874,13.874,0,0,0,4.8,23.508L2.25,31.029l7.822-2.485A14.152,14.152,0,0,0,31.029,16.269,14.074,14.074,0,0,0,16.9,2.25Zm7.025,19.343a3.649,3.649,0,0,1-2.5,1.611c-.663.035-.681.514-4.294-1.056s-5.785-5.386-5.956-5.632a6.929,6.929,0,0,1-1.333-3.755,4,4,0,0,1,1.375-2.937,1.384,1.384,0,0,1,.978-.412c.284,0,.469-.008.679,0s.526-.044.8.683.928,2.515,1.012,2.7a.655.655,0,0,1,.007.628,2.453,2.453,0,0,1-.383.583c-.189.2-.4.452-.565.607-.188.171-.384.358-.186.724a10.811,10.811,0,0,0,1.913,2.553,9.857,9.857,0,0,0,2.833,1.883c.354.193.566.172.785-.06s.94-1.012,1.195-1.36.492-.281.819-.15,2.066,1.064,2.42,1.256.591.291.675.443A2.967,2.967,0,0,1,23.926,21.593Z"
          transform="translate(-2.25 -2.25)"
          fill="#FF3D77"
        />
      </svg>
      <span class="text-lg cursor-pointer font-bold"> Contattaci </span>
    </a>
  </div>
  <form
    id="form"
    class="px-4 pt-3"
    action="https://formspree.io/f/mpzbwaze"
    method="POST"
    target="frame"
  >
    <!-- <form id="form" class="px-4 pt-3" target="frame"> -->

    <div class="pb-3">
      <input
        id="nome-input"
        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        name="name"
        id="full-name"
        placeholder="Nome e Cognome"
        required
      />
    </div>
    <div class="pb-3">
      <input
        id="email-input"
        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="email"
        name="_replyto"
        id="email-address"
        placeholder="Email"
        required
      />
    </div>
    <div class="pb-3">
      <input
        id="numero-input"
        class="shadow border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="telephone"
        name="telephone"
        id="telephone"
        placeholder="Cellulare"
        required
      />
    </div>
    <div class="pb-5">
      <label>
        <input
          id="checkbox"
          type="checkbox"
          name="maggiorenne"
          value="true"
          required
        />
        Dichiaro di essere maggiorenne
      </label>
    </div>
    <div class="text-center">
      <button
        id="send-btn"
        type="submit"
        class="rounded-lg py-2 px-12 text-white"
      >
        Invia
      </button>
    </div>
  </form>
  <div id="msg" class="p-4 text-center">
    <h1 class="text-xl pb-2">
      Grazie per aver scelto
      <span class="font-bold">Dr.Feel!</span>
    </h1>
    A breve sarai ricontattato dal nostro
    <span class="font-bold">Health Manager.</span>
  </div>
  <iframe name="frame" width="0" height="0"> </iframe>
</div>
