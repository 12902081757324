import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-document-box',
  templateUrl: './document-box.component.html',
  styleUrls: ['./document-box.component.scss'],
})
export class DocumentBoxComponent implements OnInit {
  @Input() doc: ProfiloDocumentDatum;
  private breakPoint: number = 767;
  public isMobile: boolean = false;

  constructor(private http: HttpClient, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver.observe([`(max-width: ${this.breakPoint}px)`]).subscribe((state: any) => {
      this.isMobile = state.matches;
    });
  }

  parseDocType(docType: string) {
    switch (docType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpg';
      case 'document/doc':
        return 'doc';
      case 'application/pdf':
        return 'pdf';
      case 'other':
        return 'etc';
      default:
        return 'etc';
    }
  }

  parseBytes(bytes: any) {
    const floatBytes = parseFloat(bytes);
    if (floatBytes > 1024) {
      return `${Math.round(floatBytes / 1024)} KB`;
    } else {
      return `${bytes} Bytes`;
    }
  }

  downloadDocument(doc: ProfiloDocumentDatum) {
    console.log('download document', doc);
    const url = `${environment.NEW_API}/documents/${doc.id}/content/`;

    return this.http
      .get(url, { responseType: 'blob', headers: { Accept: doc.contentType } })
      .subscribe((blob) => {
        console.log(blob)
        saveAs(blob, doc.name);
      });
  }
}
