import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { switchMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as MeActions from './me.actions';
import { Me } from 'src/app/model/Me';
import { of } from 'rxjs';
import { Measurement } from 'src/app/model/Measurement';
import { UserInfo } from 'src/app/model/UserInfo';

@Injectable()
export class MeEffects {
  fetchMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.fetchMe),
      switchMap(() => {
        return this.http.get<Me>(environment.NEW_API + '/profile');
      }),
      map((me) => {
        return MeActions.setMe({ me: me.data });
      }),
      catchError((error) => {
        console.error('Error while fetching me:', error);
        return of(
          MeActions.fetchMeFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  modifyMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.updateMe),
      switchMap((action) => {
        return this.http.put<Me>(environment.NEW_API + '/profile', action.me);
      }),
      map((me) => {
        return MeActions.setMe({ me: me.data });
      })
    )
  );

  movergyDisplay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.movergyDisplay),
      switchMap((action) => {
        return this.http.get<any>(environment.NEW_API + '/my-wellness/movergy');
      }),
      map((movergy) => {
        return MeActions.movergyDisplaySuccess({
          movergyDisplay: movergy.data,
        });
      }),
      catchError((error) => {
        return of(
          MeActions.movergyDisplayFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  myWellnessEnable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.myWellnessEnable),
      switchMap((action) => {
        return this.http.patch<any>(
          environment.NEW_API + '/my-wellness/enable',
          {}
        );
      }),
      map((movergy) => {
        // console.log('mywellness enable from effects', movergy.data);
        return MeActions.myWellnessEnableSuccess({
          wellnessEnabled: movergy.data,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching movergy:', error);
        return of(
          MeActions.myWellnessEnableFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  //   On MyWellnessEnable success fire the action to fetch the movergy display
  myWellnessEnableSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.myWellnessEnableSuccess),
      map(() => {
        return MeActions.movergyDisplay();
      })
    )
  );

  fetchMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.fetchMeasurement),
      switchMap(() => {
        return this.http.get<Measurement>(
          environment.NEW_API + '/profile/measurement'
        );
      }),
      map((data) => {
        return MeActions.fetchMeasurementSuccess({ measurement: data });
      }),
      catchError((error) => {
        console.error('Error while fetching measurements:', error);
        return of(
          MeActions.fetchMeasurementFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  postMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.postMeasurement),
      switchMap((action) => {
        return this.http.post<Measurement>(
          environment.NEW_API + '/profile/measurement',
          action.measurement
        );
      }),
      map((data) => {
        return MeActions.postMeasurementSuccess({ measurement: data });
      }),
      catchError((error) => {
        console.error('Error while fetching measurements:', error);
        return of(
          MeActions.postMeasurementFail({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeActions.fetchUserInfo),
      switchMap(() => {
        console.log('fetching user info');
        return this.http.get<UserInfo>(environment.BFF_PATIENT + '/user/info');
      }),
      map((userInfo) => {
        console.log('User info from effects:', userInfo);
        return MeActions.fetchUserInfoSuccess({ userInfo: userInfo });
      }),
      catchError((error) => {
        console.log('Error from effects:', error);
        return of(MeActions.fetchUserInfoFail({ error: error }));
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
