import { Action, createReducer, on } from '@ngrx/store';
import { Me, MeData } from 'src/app/model/Me';
import * as MeActions from './me.actions';
import * as AuthActions from '../../../auth/store/auth.actions';
import { MeasurementData } from 'src/app/model/Measurement';
import { UserInfo } from 'src/app/model/UserInfo';

export interface State {
  me: MeData | null;
  measurements: MeasurementData | null;
  error: boolean;
  errorMessage: any;
  loading: boolean;
  updateModal: boolean;
  movergyDisplay: any;
  movergyLoading: boolean;
  userInfo: UserInfo | null;
}

const initialState: State = {
  me: null,
  measurements: null,
  error: false,
  errorMessage: null,
  loading: false,
  updateModal: false,
  movergyDisplay: null,
  movergyLoading: false,
  userInfo: null,
};

const _meReducer = createReducer(
  initialState,

  on(MeActions.fetchMe, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.setMe, (state, action) => ({
    ...state,
    me: action.me,
    loading: false,
    updateModal: false,
  })),

  on(MeActions.fetchMeFail, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(MeActions.updateMe, (state, action) => ({
    ...state,
    loading: true,
    updateModal: true,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    me: null,
    loading: false,
    updateModal: false,
  })),

  on(MeActions.myWellnessEnable, (state) => ({
    ...state,
    movergyLoading: true,
  })),

  on(MeActions.myWellnessEnableFail, (state, action) => ({
    ...state,
    movergyLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(MeActions.movergyDisplay, (state) => ({
    ...state,
    movergyLoading: true,
  })),

  on(MeActions.movergyDisplaySuccess, (state, action) => ({
    ...state,
    movergyDisplay: action.movergyDisplay,
    movergyLoading: false,
  })),

  on(MeActions.movergyDisplayFail, (state, action) => ({
    ...state,
    movergyLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(MeActions.fetchMeasurement, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.fetchMeasurementSuccess, (state, action) => ({
    ...state,
    measurements: action.measurement.data,
    loading: false,
  })),

  on(MeActions.postMeasurement, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.postMeasurementSuccess, (state, action) => ({
    ...state,
    measurements: action.measurement.data,
    loading: false,
  })),

  on(MeActions.fetchUserInfo, (state) => ({
    ...state,
    loading: true,
  })),

  on(MeActions.fetchUserInfoSuccess, (state, action) => ({
    ...state,
    userInfo: action.userInfo,
    loading: false,
  })),

  on(MeActions.fetchUserInfoFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loading: false,
  }))
);

export function meReducer(state: State | undefined, action: Action) {
  return _meReducer(state, action);
}
