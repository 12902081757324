import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { Router } from '@angular/router';
import { MeData } from 'src/app/model/Me';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() fullName: string | undefined = ' ';
  @Input() me: MeData | null;
  public avatarUrl: any;
  private hasImageProfile: boolean = false;
  private storeSubscription: Subscription;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };
  private postUrl: string = environment.NEW_API + '/profile/image';

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private http: HttpClient
  ) {}

  uploadImageProfile(file: any) {
    const formData = new FormData();
    formData.append('image', file.data, file.data.name);
    return this.http
      .post(this.postUrl, formData, this.payload)
      .subscribe((data) => {
        console.log('success', data);
        // this.store.dispatch(ProfileActions.fetchImageProfile());
        this.router.navigateByUrl('/profilo');
      });
  }

  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadImageProfile(fileParams);
    };
    fileUpload.click();
  }

  ngOnInit(): void {
    if (this.hasImageProfile) {
      this.storeSubscription = this.store
        .select('profile')
        .subscribe((profile) => {
          this.avatarUrl = profile.imageProfile;
        });
    } else {
      this.getAvatar();
      this.hasImageProfile = true;
    }
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
    this.router.navigate(['/loading']);
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
