<!-- <div class="container mt-5">
</div> -->

<ng-container *ngIf="productDetails">
  <div class="container my-5">

    <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>

    <app-loading-spinner *ngIf="loading"></app-loading-spinner>

    <!-- BANNER -->
    <section id="banner">
      <div class="w-50 h-100 d-flex flex-column justify-content-between">
        <div>
          <h2 class="do3-fw800">{{ productDetails.data.name }}</h2>

          <!-- bottone torna indietro -->
          <div class="col d-flex align-items-center">
            <button class="do3-back-btn col-12 p-0" (click)="goBack()">
              <mat-icon>arrow_back</mat-icon>
              Torna indietro
            </button>
          </div>
        </div>

        <div id="percorso-description">
          <h1 class="title do3-fw800 text-gradient-drfeel">
            {{ productDetails.data.name }}
          </h1>
          <p class="text">
            Un percorso di prevenzione e benessere completo, modellato sulle tue
            esigenze, dove per la prima volta un team multidisciplinare si prende costantemente
            cura di te.
          </p>

          <button
            class="do3-secondary-btn lightBlue mb-0 percorso-btn"
            (click)="payOrder(productDetails.data.id)"
          >
            Attiva percorso
          </button>
        </div>
      </div>
    </section>

    <section id="percorso-details" class="row">
      <div class="col">
        <h3>Incluso nel percorso:</h3>
        <ul>
          <li>Check-up e valutazione iniziale</li>
          <li>Fino a 12 videoconsulti multidisciplinari</li>
          <li>Piano benessere personalizzato</li>
          <li>Dossier clinico digitale</li>
          <li>Health Manager h24</li>
          <li>Valutazione progressiva dei risultati</li>
        </ul>
        <h3>Specialisti:</h3>
        <span>Medico Internista, Nutrizionista, Psicologo, Personal Trainer</span>
      </div>
      <div class="col">
        <h3>Come funziona:</h3>
        <ul class="steps p-0">
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Attiva il percorso
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Fai il check-up
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Accedi alle visite multidisciplinari
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Ottieni i piani personalizzati
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Monitora i progressi
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Effettua le visite intermedie
          </li>
          <li class="d-flex align-items-center">
            <div class="circle lightBlue"></div>
            Ricevi la valutazione finale
          </li>
        </ul>
      </div>
    </section>

    <section id="price">
      <p>Prezzo percorso: € {{ productDetails.data.price | number:'1.2-2' }}</p>

      <button
        class="do3-secondary-btn lightBlue mb-0 percorso-btn"
        (click)="payOrder(productDetails.data.id)"
      >
        Attiva percorso
      </button>
    </section>

  </div>
</ng-container>
