import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { resetPrenotazioni } from '../store/actions/prenotazione.action';

@Component({
  selector: 'app-successo-prenotazione',
  templateUrl: './successo-prenotazione.component.html',
  styleUrls: ['./successo-prenotazione.component.scss'],
})
export class SuccessoPrenotazioneComponent implements OnInit {
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();
  public selection: string = '';

  constructor(private router: Router, private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {}

  doChangeStep(direction: 'forward' | 'back') {
    this.changeStep.emit(direction);
  }

  toRichieste() {
    this.store.dispatch(resetPrenotazioni());
    this.router.navigate(['/home'], {
      queryParams: { tab: 'richiesteTab' },
    });
  }
}
