import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AgendaRoutingModule} from "./agenda-routing.module";
import {CalendarModule, DateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {AgendaComponent} from "./components/agenda.component";
import {FormsModule} from "@angular/forms";

registerLocaleData(localeIt);

@NgModule({
    declarations: [AgendaComponent],
    exports: [
        AgendaComponent
    ],
    imports: [
        CommonModule,
        AgendaRoutingModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        FormsModule
    ]
})
export class AgendaModule { }
