<div class="container my-5">
  <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>

  <div class="col d-flex align-items-center">
    <button class="do3-back-btn col-12 p-0" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      Torna indietro
    </button>
  </div>

  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div *ngIf="me && !loading" class="info-profilo mt-4 mb-3">
    <div class="d-md-none">
      <h1 class="do3-lightblue-text do3-fw800">Profilo</h1>
      <h2 class="do3-fw600 do3-blue-text">
        Qui puoi modificare i dati del tuo profilo
      </h2>
    </div>

    <h1 class="do3-fw800 do3-blue-text d-none d-md-block">
      Qui puoi modificare i dati del tuo profilo
    </h1>
    <div class="info-profilo-form" *ngIf="!loading">
      <form #f="ngForm" (ngSubmit)="onSaveProfile(f)" class="row">
        <!-- ERRORS -->
        <div class="col-12">
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
          >
            <h5>Nome</h5>
            <ul>
              <li *ngIf="firstName.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
          >
            <h5>Cognome</h5>
            <ul>
              <li *ngIf="lastName.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="fiscalCode.invalid && fiscalCode.touched"
          >
            <h5>Codice Fiscale</h5>
            <ul>
              <li *ngIf="fiscalCode.errors?.['required']">
                il campo è obbligatorio
              </li>
              <li *ngIf="!!fiscalCode.errors?.['pattern']">
                Codice fiscale errato
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)"
          >
            <h5>Data di Nascita</h5>
            <ul>
              <li *ngIf="birthDate.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="bornCity.invalid && (bornCity.dirty || bornCity.touched)"
          >
            <h5>Luogo di Nascita</h5>
            <ul>
              <li *ngIf="bornCity.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="gender.invalid && (gender.dirty || gender.touched)"
          >
            <h5>Sesso</h5>
            <ul>
              <li *ngIf="gender.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="email.invalid && email.touched"
          >
            <h5>Email</h5>
            <ul>
              <li *ngIf="email.errors?.['required']">
                il campo è obbligatorio
              </li>
              <li *ngIf="!!email.errors?.['pattern']">Email errata</li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="phoneNumber.invalid && phoneNumber.touched"
          >
            <h5>Cellulare</h5>
            <ul>
              <li *ngIf="phoneNumber.errors?.['required']">
                il campo è obbligatorio
              </li>
              <li
                *ngIf="phoneNumber.errors?.['minlength'] || phoneNumber.errors?.['pattern']"
              >
                Numero di telefono errato
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="street.invalid && (street.dirty || street.touched)"
          >
            <h5>Indirizzo</h5>
            <ul>
              <li *ngIf="street.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="zip.invalid && (zip.dirty || zip.touched)"
          >
            <h5>Codice Postale</h5>
            <ul>
              <li *ngIf="zip.errors?.['required']">il campo è obbligatorio</li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="city.invalid && (city.dirty || city.touched)"
          >
            <h5>Città</h5>
            <ul>
              <li *ngIf="city.errors?.['required']">il campo è obbligatorio</li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="region.invalid && (region.dirty || region.touched)"
          >
            <h5>Regione</h5>
            <ul>
              <li *ngIf="region.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="country.invalid && (country.dirty || country.touched)"
          >
            <h5>Nazione</h5>
            <ul>
              <li *ngIf="country.errors?.['required']">
                il campo è obbligatorio
              </li>
            </ul>
          </div>
        </div>
        <!-- ERRORS END -->

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #firstName="ngModel"
            name="firstName"
            class="form-control no-box"
            id="firstName"
            placeholder="Nome"
            type="text"
            [ngModel]="me.firstName"
          />
          <label *ngIf="!firstName.invalid" for="firstName">Nome*</label>
          <label class="text-danger" *ngIf="firstName.invalid" for="firstName"
            >Nome*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #lastName="ngModel"
            name="lastName"
            class="form-control no-box"
            id="lastName"
            placeholder="Cognome"
            type="text"
            [ngModel]="me.lastName"
          />
          <label *ngIf="!lastName.invalid" for="lastName">Cognome*</label>
          <label class="text-danger" *ngIf="lastName.invalid" for="lastName"
            >Cognome*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #fiscalCode="ngModel"
            name="fiscalCode"
            class="form-control no-box"
            id="fiscalCode"
            placeholder="Codice fiscale"
            type="text"
            [ngModel]="me.fiscalCode"
            pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$"
          />
          <label *ngIf="!fiscalCode.invalid" for="fiscalCode"
            >Codice Fiscale*</label
          >
          <label class="text-danger" *ngIf="fiscalCode.invalid" for="fiscalCode"
            >Codice Fiscale*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #birthDate="ngModel"
            name="birthDate"
            class="form-control no-box"
            id="birthDate"
            placeholder="Data"
            type="date"
            [ngModel]="me.birthDate"
          />
          <label *ngIf="!birthDate.invalid" for="birthDate"
            >Data di Nascita*</label
          >
          <label class="text-danger" *ngIf="birthDate.invalid" for="birthDate"
            >Data di Nascita*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #bornCity="ngModel"
            name="bornCity"
            class="form-control no-box"
            id="bornCity"
            placeholder="Luogo di Nascita"
            type="text"
            [ngModel]="me.bornCity"
          />
          <label *ngIf="!bornCity.invalid">Luogo di Nascita*</label>
          <label class="text-danger" *ngIf="bornCity.invalid"
            >Luogo di Nascita*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <select
            required
            #gender="ngModel"
            class="form-select no-box do3-fw600 do3-gray-text"
            name="gender"
            id="gender"
            [ngModel]="me.gender"
          >
            <option value="MALE">Uomo</option>
            <option value="FEMALE">Donna</option>
            <option value="OTHER">Preferisco non rispondere</option>
          </select>
          <label *ngIf="!gender.invalid" for="gender">Sesso*</label>
          <label class="text-danger" *ngIf="gender.invalid" for="gender"
            >Sesso*</label
          >
        </div>

        <div class="form-floating col-12 col-md-6">
          <input
            required
            #email="ngModel"
            name="email"
            class="form-control no-box"
            id="email"
            placeholder="Email"
            [ngModel]="me.email"
            type="email"
            disabled="true"
            pattern='((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))'
          />
          <label *ngIf="!email.invalid" for="email">Email*</label>
          <label class="text-danger" *ngIf="email.invalid" for="email"
            >Email*</label
          >
        </div>

        <div class="form-floating col-12 col-md-6">
          <input
            required
            #phoneNumber="ngModel"
            name="phoneNumber"
            class="form-control no-box tel"
            id="phoneNumber"
            placeholder="Cellulare"
            type="number"
            [ngModel]="me.phoneNumber"
            minlength="10"
            pattern="^(\+?\d{10,14})$"
          />
          <label *ngIf="!phoneNumber.invalid" for="phoneNumber"
            >Cellulare*</label
          >
          <label
            class="text-danger"
            *ngIf="phoneNumber.invalid"
            for="phoneNumber"
            >Cellulare*</label
          >
        </div>

        <div class="form-floating col-12 col-md-8">
          <input
            required
            #street="ngModel"
            name="street"
            class="form-control no-box"
            id="street"
            placeholder="Indirizzo"
            type="text"
            [ngModel]="me.street"
          />
          <label *ngIf="!street.invalid" for="street">Indirizzo*</label>
          <label class="text-danger" *ngIf="street.invalid" for="street"
            >Indirizzo*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #zip="ngModel"
            name="zip"
            class="form-control no-box"
            id="zip"
            placeholder="Codice Postale"
            type="number"
            [ngModel]="me.zip"
          />
          <label *ngIf="!zip.invalid" for="zip">Codice Postale*</label>
          <label class="text-danger" *ngIf="zip.invalid" for="zip"
            >Codice Postale*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #city="ngModel"
            name="city"
            class="form-control no-box"
            id="city"
            placeholder="Città"
            type="text"
            [ngModel]="me.city"
          />
          <label *ngIf="!city.invalid" for="city">Città*</label>
          <label class="text-danger" *ngIf="city.invalid" for="city"
            >Città*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #region="ngModel"
            name="region"
            class="form-control no-box"
            id="region"
            placeholder="Regione"
            type="text"
            [ngModel]="me.region"
          />
          <label *ngIf="!region.invalid" for="street">Regione*</label>
          <label class="text-danger" *ngIf="region.invalid" for="street"
            >Regione*</label
          >
        </div>

        <div class="form-floating col-12 col-md-4">
          <input
            required
            #country="ngModel"
            name="country"
            class="form-control no-box"
            id="country"
            placeholder="Regione"
            type="text"
            [ngModel]="me.country"
          />
          <label *ngIf="!country.invalid" for="country">Nazione*</label>
          <label class="text-danger" *ngIf="country.invalid" for="country"
            >Nazione*</label
          >
        </div>

        <!-- Fine Profilo -->

        <!-- Misurazioni -->
        <div class="peso form-floating col-12 col-md-4">
          <input
            #weight="ngModel"
            name="weight"
            class="form-control no-box"
            id="weight"
            placeholder="Peso"
            type="number"
            [ngModel]="misurazioni.weight"
          />
          <label *ngIf="!weight.invalid" for="weight">Peso</label>
          <label class="text-danger" *ngIf="weight.invalid" for="weight"
            >Peso</label
          >
        </div>
        <div class="altezza form-floating col-12 col-md-4">
          <input
            #height="ngModel"
            name="height"
            class="form-control no-box"
            id="height"
            placeholder="Altezza"
            type="number"
            [ngModel]="misurazioni.height"
          />
          <label *ngIf="!height.invalid" for="country">Altezza</label>
          <label class="text-danger" *ngIf="height.invalid" for="country"
            >Altezza</label
          >
        </div>
        <div class="vita form-floating col-12 col-md-4">
          <input
            #waistCircumference="ngModel"
            name="waistCircumference"
            class="form-control no-box"
            id="waistCircumference"
            placeholder="Circonferenza vita"
            type="number"
            [ngModel]="misurazioni.waistCircumference"
          />
          <label *ngIf="!waistCircumference.invalid" for="waistCircumference"
            >Circonferenza vita</label
          >
          <label
            class=""
            *ngIf="misurazioni.waistCircumference"
            for="waistCircumference"
            >Circonferenza vita
          </label>
        </div>
        <div class="addome form-floating col-12 col-md-4">
          <input
            #abdomenCircumference="ngModel"
            name="abdomenCircumference"
            class="form-control no-box"
            id="abdomenCircumference"
            placeholder="Circonferenza addominale"
            type="number"
            [ngModel]="misurazioni.abdomenCircumference"
          />
          <label *ngIf="!abdomenCircumference.invalid" for="country"
            >Circonferenza addominale</label
          >
          <label
            class="text-danger"
            *ngIf="abdomenCircumference.invalid"
            for="abdomenCircumference"
            >Circonferenza addominale</label
          >
        </div>
        <div class="fianchi form-floating col-12 col-md-4">
          <input
            #flanksCircumference="ngModel"
            name="flanksCircumference"
            class="form-control no-box"
            id="flanksCircumference"
            placeholder="Circonferenza fianchi"
            type="number"
            [ngModel]="misurazioni.flanksCircumference"
          />
          <label *ngIf="!flanksCircumference.invalid" for="flanksCircumference"
            >Circonferenza fianchi</label
          >
          <label
            class="text-danger"
            *ngIf="flanksCircumference.invalid"
            for="flanksCircumference"
            >Circonferenza fianchi</label
          >
        </div>
        <div class="coscia form-floating col-12 col-md-4">
          <input
            #thighCircumference="ngModel"
            name="thighCircumference"
            class="form-control no-box"
            id="thighCircumference"
            placeholder="Circonferenza coscia"
            type="number"
            [ngModel]="misurazioni.thighCircumference"
          />
          <label *ngIf="!thighCircumference.invalid" for="thighCircumference"
            >Circonferenza coscia</label
          >
          <label
            class="text-danger"
            *ngIf="thighCircumference.invalid"
            for="thighCircumference"
            >Circonferenza coscia</label
          >
        </div>
        <div class="polso form-floating col-12 col-md-4">
          <input
            #wristCircumference="ngModel"
            name="wristCircumference"
            class="form-control no-box"
            id="wristCircumference"
            placeholder="Circonferenza polso"
            type="number"
            [ngModel]="misurazioni.wristCircumference"
          />
          <label *ngIf="!wristCircumference.invalid" for="wristCircumference"
            >Circonferenza polso</label
          >
          <label
            class="text-danger"
            *ngIf="wristCircumference.invalid"
            for="wristCircumference"
            >Circonferenza polso</label
          >
        </div>
        <div class="diastolica form-floating col-12 col-md-4">
          <input
            #diastolicPressure="ngModel"
            name="diastolicPressure"
            class="form-control no-box"
            id="diastolicPressure"
            placeholder="Pressione diastolica"
            type="number"
            [ngModel]="misurazioni.diastolicPressure"
          />
          <label *ngIf="!diastolicPressure.invalid" for="diastolicPressure"
            >Pressione diastolica</label
          >
          <label
            class="text-danger"
            *ngIf="diastolicPressure.invalid"
            for="diastolicPressure"
            >Pressione diastolica</label
          >
        </div>
        <div class="sistolica form-floating col-12 col-md-4">
          <input
            #systolicPressure="ngModel"
            name="systolicPressure"
            class="form-control no-box"
            id="systolicPressure"
            placeholder="Pressione sistolica"
            type="number"
            [ngModel]="misurazioni.systolicPressure"
          />
          <label *ngIf="!systolicPressure.invalid" for="systolicPressure"
            >Pressione sistolica</label
          >
          <label
            class="text-danger"
            *ngIf="systolicPressure.invalid"
            for="systolicPressure"
            >Pressione sistolica</label
          >
        </div>

        <small>(*) il campo è obbligatorio</small>

        <div class="d-flex justify-content-between mt-5">
          <button
            class="do3-secondary-btn light-blue ml-0"
            [disabled]="!f.valid || !f.dirty"
            type="submit"
          >
            SALVA MODIFICHE
          </button>
          <!-- <button class="do3-secondary-btn light-blue ml-0" type="submit">SALVA MODIFICHE</button> -->
          <!-- <button class="do3-secondary-btn red">ELIMINA IL TUO ACCOUNT</button> -->
        </div>
      </form>
    </div>
  </div>

  <button
    type="button"
    style="visibility: hidden"
    #modal
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mb-0" id="exampleModalLabel">
            Modifiche salvate
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
