import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();

  // for navbar
  public authSubscription!: Subscription;
  public profileSubscription!: Subscription;
  public auth!: any | null;
  public profileType: string | undefined = 'free';

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  ngOnInit() {
    this.authSubscription = this.store
      .select('auth')
      .pipe(map((authState) => authState))
      .subscribe((authState) => {
        this.auth = authState.userData;
      });
  }

  public goToHome() {
    this.router.navigate(['/home'], {
      queryParams: { tab: 'homeTab' },
    });
  }

  onLogout() {
    this.router.navigate(['/loading']);
    this.store.dispatch(AuthActions.logout());
  }
}
