import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { Router } from '@angular/router';
import * as RichiesteActions from '../../richieste/store/richieste.actions';
import { Subscription } from 'rxjs';
import { RichiestaDatum, Meta } from 'src/app/model/Richiesta';
import { OrdersData } from 'src/app/model/Orders';
import { allCoupons } from '../store/profilo.actions';
import { Location } from '@angular/common';
import { CouponDatum } from 'src/app/model/Coupon';

@Component({
  selector: 'app-fatturazione',
  templateUrl: './fatturazione.component.html',
  styleUrls: ['./fatturazione.component.scss'],
})
export class FatturazioneComponent implements OnInit, OnDestroy {
  public richieste: RichiestaDatum[];
  private storeSub: Subscription;
  private profileSub: Subscription;
  public loading: boolean = true;
  public richiesteMeta: Meta | null;
  public hasCronologia: boolean = false;
  public page: number = 0;
  private openPage: number = 0;
  public allOrders: OrdersData[] = [];
  public coupon: CouponDatum | null;

  constructor(
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.store.dispatch(RichiesteActions.fetchAllRichieste({}));
    this.store.dispatch(allCoupons());
    this.storeSub = this.store.select('richieste').subscribe((richieste) => {
      this.richiesteMeta = richieste.allRichiesteMeta;
      this.richieste = richieste.allRichieste.filter(
        (richiesta) => richiesta.paymentInfo?.status !== 'DELETED'
      );
      this.loading = richieste.loading;
      this.allOrders = richieste.allOrders ? richieste.allOrders : [];
    });
    this.profileSub = this.store.select('profile').subscribe((profile) => {
      if (profile.coupons) {
        this.coupon = profile.coupons.filter(
          (item: any) => item.status !== 'USED'
        )[0];
      }
    });
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.profileSub) {
      this.profileSub.unsubscribe();
    }
  }

  handlePageClick(page: any) {
    this.page = page;
    console.log(this.page);
    this.changePageOpen(this.page);
  }

  changePageOpen(page: any) {
    this.openPage = page;
    console.log('page open', this.openPage);
    this.store.dispatch(
      RichiesteActions.fetchAllRichieste({
        searchRequest: true,
        // statusValue: 'CLOSED',
        page: this.openPage,
      })
    );
  }

  payOrder(orderId: string) {
    const pathIndex = window.location.href.lastIndexOf('/');
    const path = window.location.href.slice(0, pathIndex);
    setTimeout(() => {
      const checkOut: any = {
        orderId: orderId,
        successUrl: path + '/success',
        cancelUrl: path + '/cancel',
      };
      if (this.coupon && this.coupon.amount > 1.0) {
        checkOut.couponCode = this.coupon.code;
      }
      this.store.dispatch(RichiesteActions.orderCheckout(checkOut));
    }, 1000);
  }

  goBack() {
    this.location.back();
  }
}
