import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as fromApp from '../../../store/app.reducer';
import { Store } from '@ngrx/store';
import { RichiestaDatum, Meta } from 'src/app/model/Richiesta';
import * as RichiesteActions from '../../richieste/store/richieste.actions';
import { Location } from '@angular/common';

@Component({
  selector: 'app-anamnesi',
  templateUrl: './anamnesi.component.html',
  styleUrls: ['./anamnesi.component.scss'],
})
export class AnamnesiComponent implements OnInit {
  private storeSubscription: Subscription;
  public loading: boolean = true;
  public richieste: RichiestaDatum[];
  public richiesteMeta: Meta | null;
  public hasCronologia: boolean = false;
  public page: number = 0;
  private openPage: number = 0;
  constructor(
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.store.dispatch(RichiesteActions.fetchAllRichieste({}));
    this.storeSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        this.richiesteMeta = richieste.allRichiesteMeta;

        this.richieste = richieste.allRichieste.filter(
          (richiesta) =>
            richiesta.hasTriage == true && richiesta.needTriage == true
        );
        this.loading = richieste.cronologiaLoading;
      });
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  handlePageClick(page: any) {
    this.page = page;
    console.log(this.page);
    this.changePageOpen(this.page);
  }

  changePageOpen(page: any) {
    this.openPage = page;
    console.log('page open', this.openPage);
    this.store.dispatch(
      RichiesteActions.fetchAllRichieste({
        searchRequest: true,
        // statusValue: 'CLOSED',
        page: this.openPage,
      })
    );
  }

  goBack() {
    this.location.back();
  }
}
