import { createAction, props } from '@ngrx/store';
import { MeData } from 'src/app/model/Me';
import { Measurement, MeasurementData } from 'src/app/model/Measurement';
import { UserInfo } from 'src/app/model/UserInfo';

export const setMe = createAction(
  '[Home] Set Me',
  props<{
    me: MeData;
  }>()
);

export const updateMe = createAction(
  '[Home] Update Me',
  props<{
    me: MeData;
  }>()
);

export const fetchMeFail = createAction(
  '[Home] Fetch Me Fail',
  props<{
    error: any;
  }>()
);

export const myWellnessEnable = createAction('[Home] My Wellness Enable');

export const myWellnessEnableSuccess = createAction(
  '[Home] My Wellness Enable Success',
  props<{
    wellnessEnabled: boolean;
  }>()
);

export const myWellnessEnableFail = createAction(
  '[Home] My Wellness Enable Fail',
  props<{
    error: any;
  }>()
);

export const movergyDisplay = createAction('[Home] Movergy Display');

export const movergyDisplaySuccess = createAction(
  '[Home] Movergy Display Success',
  props<{
    movergyDisplay: any;
  }>()
);

export const movergyDisplayFail = createAction(
  '[Home] Movergy Display Fail',
  props<{
    error: any;
  }>()
);

export const myWellnessShare = createAction(
  '[Home] My Wellness Share',
  props<{
    doctorId: string;
  }>()
);

export const myWellnessShareSuccess = createAction(
  '[Home] My Wellness Share Success',
  props<{
    wellnessShared: boolean;
  }>()
);

export const myWellnessShareFail = createAction(
  '[Home] My Wellness Share Fail',
  props<{
    error: any;
  }>()
);

export const fetchMe = createAction('[Home] Fetch Me');

export const fetchMeasurement = createAction('[Home] Fetch Measurement');

export const fetchMeasurementSuccess = createAction(
  '[Home] Fetch Measurement Success',
  props<{
    measurement: Measurement;
  }>()
);

export const fetchMeasurementFail = createAction(
  '[Home] Fetch Measurement Fail',
  props<{
    error: any;
  }>()
);

export const postMeasurement = createAction(
  '[Home] Post Measurement',
  props<{
    measurement: MeasurementData;
  }>()
);

export const postMeasurementSuccess = createAction(
  '[Home] Post Measurement Success',
  props<{
    measurement: Measurement;
  }>()
);

export const postMeasurementFail = createAction(
  '[Home] Post Measurement Fail',
  props<{
    error: any;
  }>()
);

export const fetchUserInfo = createAction('[Home] Fetch User Info');

export const fetchUserInfoFail = createAction(
  '[Home] Fetch User Info Fail',
  props<{
    error: any;
  }>()
);

export const fetchUserInfoSuccess = createAction(
  '[Home] Fetch User Info Success',
  props<{
    userInfo: UserInfo;
  }>()
);
