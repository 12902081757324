<div class="container">
  <h1 class="do3-fw800 mt-3">
    Contatti
  </h1>
</div>
<div class="super-cont">
  <div class="containe">
    <img src="../../../assets/images/contatti.png" alt="Contatti">
    <h2 class="text-gradient-drfeel">Sempre al tuo fianco</h2>
    <h3>Hai bisogno di aiuto? Siamo qui per te!</h3>
    <p>Il servizio di assistenza è attivo dal lunedì al venerdì dalle ore 9.00 alle ore 18.00</p>
    <a class="email" href="mailto:assistenza@drfeel.com">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
          fill="#ff3d77" />
      </svg>
      assistenza@drfeel.com
    </a>
  </div>
</div>