import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import { BehaviorSubject, Observable } from 'rxjs';
import { resetPrenotazioni } from '../prenotazione/store/actions/prenotazione.action';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, sendEmailVerification } from '@angular/fire/auth';
import { allCoupons, postProfileInfos } from './store/profilo.actions';
import { fetchMe } from 'src/app/core/header/store/me.actions';
import { CouponDatum } from 'src/app/model/Coupon';

type Step =
  | 'home'
  | 'richieste'
  | 'info'
  | 'cronologia'
  | 'documenti'
  | 'fatturazione'
  | 'anamnesi'
  | 'privacy'
  | 'movergy';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss'],
})
export class ProfiloComponent implements OnInit, OnDestroy {
  public hide = true;
  public activeTab: string = 'richieste';
  public emailVerified: boolean = false;
  public emailSent: boolean = false;
  public ordersToPay: any[] = [];
  public receivedMessage: string;
  public docType: string;
  private profileSubscription: Subscription;
  private meSubscription: Subscription;
  private paramSubscription: Subscription;
  public couponInserted: boolean;
  public couponCheckLoading: boolean = true;
  public coupons: CouponDatum[] | null = null;
  private switchTab: boolean = false;
  private tabToSwitchTo: string = '';
  public accountType: string | undefined = 'FREE';
  public couponCode: string = '';
  private authSubscription: Subscription;
  public wrongCoupon: boolean = false;
  public authError: boolean = false;
  public authErrorMessage: string = '';
  public me: any;
  public meError: boolean = false;
  public meErrorMessage: string = '';
  public couponError: boolean = false;
  public couponErrorMessage: string = '';

  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>(
    'richieste'
  );

  public currentStep$: Observable<Step> = this.currentStepBs.asObservable();

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private auth: Auth
  ) {}

  ngOnInit(): void {
    // Check if user is verified
    this.emailVerified = this.auth.currentUser?.emailVerified || false;
    // console.log('verified', this.auth.currentUser?.emailVerified);
    // setTimeout(() => {
    //   if (!this.emailVerified) {
    //     window.location.reload();
    //   }
    // }, 30000);
    this.paramSubscription = this.route.queryParamMap.subscribe((params) => {
      const tabIndex = params.get('tabIndex');
      const tab = params.get('tab');
      if (tabIndex) {
        this.switchTab = true;
        this.tabToSwitchTo = tabIndex;
      }
      if (tab) {
        this.currentTab(tab);
        this.router.navigate([], { queryParams: {} });
      }
    });

    // Check for coupons
    this.checkCoupons();
    this.profileSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        // Check if the coupon is inserted
        this.coupons = profile.coupons;
        this.couponError = profile.error;
        this.couponErrorMessage = profile.errorMessage;
        if (this.switchTab) {
          this.currentTab(this.tabToSwitchTo);
          this.switchTab = false;
        }
      });
    this.meSubscription = this.store.select('me').subscribe((me) => {
      this.accountType = me.me?.profile;
      this.me = me.me;
      this.meError = me.error;
      this.meErrorMessage = me.errorMessage;

      if (this.switchTab) {
        this.currentTab(this.tabToSwitchTo);
        this.switchTab = false;
      }
    });

    this.authSubscription = this.store.select('auth').subscribe((authState) => {
      this.wrongCoupon = authState.wrongCoupon;
      this.authError = authState.error;
      this.authErrorMessage = authState.errorMessage;
    });
  }

  private checkCoupons() {
    this.store.dispatch(fetchMe());
    setTimeout(() => {
      this.store.dispatch(allCoupons());
      this.couponCheckLoading = false;
    }, 3000);
  }

  ngOnDestroy(): void {
    this.profileSubscription && this.profileSubscription.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
    this.meSubscription && this.meSubscription.unsubscribe();
    this.authSubscription && this.authSubscription.unsubscribe();
  }

  postCoupon() {
    this.couponCheckLoading = true;
    this.store.dispatch(
      postProfileInfos({
        name: this.me.firstName,
        lastname: this.me.lastName,
        coupon: this.couponCode,
      })
    );
    this.checkCoupons();
  }

  toPrenotazione() {
    this.store.dispatch(resetPrenotazioni());
    this.router.navigate(['/prenotazione'], {
      queryParams: { step: 'search' },
    });
  }

  resendVerificationEmail() {
    sendEmailVerification(this.auth.currentUser!);
    this.emailSent = true;
  }

  receiveStep(message: string) {
    this.receivedMessage = message;
    console.log(message);
    this.currentTab('richiesteTab');
  }

  onNewDoc(docType: string) {
    this.docType = docType;
    this.currentTab('documentiTab');
  }

  currentTab(currentTab: string) {
    switch (currentTab) {
      case 'homeTab':
        this.activeTab = 'home';
        this.currentStepBs.next('home');
        break;
      case 'richiesteTab':
        this.activeTab = 'richieste';
        this.currentStepBs.next('richieste');
        break;
      case 'infoTab':
        this.activeTab = 'info';
        this.currentStepBs.next('info');
        break;
      case 'cronologiaTab':
        this.activeTab = 'cronologia';
        this.currentStepBs.next('cronologia');
        break;
      case 'documentiTab':
        this.activeTab = 'documenti';
        this.currentStepBs.next('documenti');
        break;
      case 'fatturazioneTab':
        this.activeTab = 'fatturazione';
        this.currentStepBs.next('fatturazione');
        break;
      case 'anamnesiTab':
        this.activeTab = 'anamnesi';
        this.currentStepBs.next('anamnesi');
        break;
      case 'privacyTab':
        this.activeTab = 'privacy';
        this.currentStepBs.next('privacy');
        break;
      case 'movergy':
        this.activeTab = 'movergy';
        this.currentStepBs.next('movergy');
        break;
    }
  }
}
