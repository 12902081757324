<div class="plus-button" [ngClass]="!isRichiesta ? 'not-component' : 'component'" (click)="onClick()">
  <div [ngClass]="!isRichiesta ? 'plus' : 'do3-secondary-btn light-blue'">
    <ng-content></ng-content>
  </div>
  <!-- <div class="plus" *ngIf="!isRichiesta">+</div>
  <div *ngIf="isRichiesta" class=" btn btn-primary do3-secondary-btn lightBlue">Carica File</div> -->
  <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*, application/pdf"
    style="display: none" />
</div>

<!-- Button trigger modal -->
<button #openModalSuccess data-bs-toggle="modal" data-bs-target="#exampleModal"></button>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-end">
        <button type="button" class="p-3 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0 d-flex justify-content-center">
        <h2>
          <div>Documento caricato correttamente</div>
        </h2>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<button type="button" #modalerror data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-end">
        <button type="button" class="p-3 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0 d-flex justify-content-center">
        <h2>
          <div>Impossibile caricare il documento al momento</div>
        </h2>
      </div>
    </div>
  </div>
</div>