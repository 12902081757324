import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MeData } from 'src/app/model/Me';
import { Store } from '@ngrx/store';
import * as MeActions from 'src/app/core/header/store/me.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-info-profilo',
  templateUrl: './info-profilo.component.html',
  styleUrls: ['./info-profilo.component.scss'],
})
export class InfoProfiloComponent implements OnInit, OnDestroy {
  public me: MeData | null = null;
  private meSubscription: Subscription;
  private profileSubscription: Subscription;
  public meStore: MeData | null = null;
  public loading: boolean = false;
  public fullName: string = '';
  public openModal: boolean = false;
  public avatarUrl: any;
  public email: any;
  public misurazioni: any;

  @ViewChild('modal', { static: true }) modal: ElementRef;

  constructor(
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  onSaveProfile(form: NgForm) {
    form.value.fiscalCode = form.value.fiscalCode.toUpperCase();
    form.value.email = this.me?.email;
    const meForm = { ...form.value };
    const misurazioniForm = {
      abdomenCircumference: form.value.abdomenCircumference,
      diastolicPressure: form.value.diastolicPressure,
      flanksCircumference: form.value.flanksCircumference,
      height: form.value.height,
      systolicPressure: form.value.systolicPressure,
      thighCircumference: form.value.thighCircumference,
      waistCircumference: form.value.waistCircumference,
      weight: form.value.weight,
      wristCircumference: form.value.wristCircumference,
    };
    delete meForm.abdomenCircumference;
    delete meForm.diastolicPressure;
    delete meForm.flanksCircumference;
    delete meForm.height;
    delete meForm.systolicPressure;
    delete meForm.thighCircumference;
    delete meForm.waistCircumference;
    delete meForm.weight;
    delete meForm.wristCircumference;
    this.store.dispatch(MeActions.updateMe({ me: meForm }));
    if (misurazioniForm) {
      this.store.dispatch(
        MeActions.postMeasurement({ measurement: misurazioniForm })
      );
    }
  }

  ngOnInit(): void {
    this.store.dispatch(MeActions.fetchMe());
    this.store.dispatch(MeActions.fetchMeasurement());
    this.meSubscription = this.store.select('me').subscribe((me) => {
      this.me = me.me;
      this.misurazioni = me.measurements;
      this.loading = me.loading;
      this.fullName = `${me.me?.firstName} ${me.me?.lastName}`;
      this.openModal = me.updateModal;
      if (this.openModal) {
        this.modal.nativeElement.click();
      }
    });
    this.profileSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.avatarUrl = profile.imageProfile;
      });
  }

  ngOnDestroy(): void {
    this.meSubscription && this.meSubscription.unsubscribe();
    this.profileSubscription && this.profileSubscription.unsubscribe();
  }

  goBack() {
    this.location.back();
  }
}
