import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hm',
  templateUrl: './hm.component.html',
  styleUrls: ['./hm.component.scss'],
})
export class HmComponent implements OnInit {
  @Input() nomeUtente: string = '';

  constructor() {}

  ngOnInit(): void {}
}
