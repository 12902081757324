import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import {
  fetchProductDetail,
  productCheckout,
} from '../../store/dashboard.actions';
import { ProductsDetail } from 'src/app/model/Products';
import { allCoupons } from 'src/app/features/profilo/store/profilo.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-id-percorso',
  templateUrl: './id-percorso.component.html',
  styleUrls: ['./id-percorso.component.scss'],
})
export class IdPercorsoComponent implements OnInit, OnDestroy {
  private dashboardSub: Subscription;
  private profileSub: Subscription;
  private paramSubscription: any;
  private coupon: any;
  private productId: string = '';
  public productDetails: ProductsDetail | null;
  public loading: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.store.dispatch(allCoupons());
    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      if (idParam) {
        this.productId = idParam;
        this.store.dispatch(fetchProductDetail({ productId: this.productId }));
      } else {
        this.router.navigate(['/home']);
      }
    });
    this.dashboardSub = this.store
      .select('dashboard')
      .subscribe((dashboard) => {
        this.productDetails = dashboard.productDetails;
        this.loading = dashboard.loading;
      });
    this.profileSub = this.store.select('profile').subscribe((profile) => {
      this.coupon =
        profile.coupons &&
        profile.coupons.filter((item: any) => item.status !== 'USED')[0];
    });
  }

  ngOnDestroy(): void {
    this.dashboardSub && this.dashboardSub.unsubscribe();
    this.profileSub && this.profileSub.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
  }

  payOrder(productId: any) {
    const pathIndex = window.location.href.lastIndexOf('/');
    const path = window.location.href.slice(0, pathIndex);
    const checkOut: any = {
      productId: productId,
      successUrl: path + '/success',
      cancelUrl: path,
    };
    if (this.coupon && this.coupon.amount > 1.0) {
      checkOut.couponCode = this.coupon.code;
    }
    this.store.dispatch(productCheckout(checkOut));
  }

  goBack() {
    this.location.back();
  }
}
