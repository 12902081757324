import { UserCredential } from '@angular/fire/auth';
import { createAction, props } from '@ngrx/store';
import { UserData } from 'src/app/model/UserData';
import { AuthModel } from '../auth.model';

export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ userData: UserData }>()
);

export const register = createAction(
  '[Auth] Register',
  props<{
    email: string;
    password: string;
    name: string;
    lastname: string;
    coupon?: string;
    phonenumber?: string;
  }>()
);

export const registerSuccess = createAction(
  '[Auth] Register Success',
  props<{
    name: string;
    lastname: string;
    coupon?: string;
    phonenumber?: string;
  }>()
);

export const registerFail = createAction(
  '[Auth] Register Fail',
  props<{ error: any }>()
);

export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ error: any }>()
);

export const couponCheck = createAction(
  '[Auth] Coupon Check',
  props<{
    email: string;
    password: string;
    name: string;
    lastname: string;
    coupon: string;
    phonenumber?: string;
  }>()
);

export const couponCheckFail = createAction(
  '[Auth] Coupon Check Fail',
  props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');

export const loginRedirect = createAction('[Auth] Login Redirect');

export const autoLogin = createAction('[Auth] Auto Login');

export const resetPassword = createAction(
  '[Auth] Reset Password',
  props<{ email: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth] Reset Password Success'
);

export const resetPasswordFail = createAction(
  '[Auth] Reset Password Fail',
  props<{ error: any }>()
);
