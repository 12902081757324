import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as DashboardActions from './dashboard.actions';
import { Products, ProductsDetail } from 'src/app/model/Products';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}

  fetchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchProducts),
      switchMap(() => {
        return this.http.get<Products>(environment.NEW_API + '/products');
      }),
      map((products) => {
        if (products) {
          return DashboardActions.fetchProductsSuccess({
            products: products,
          });
        } else {
          return DashboardActions.fetchProductsFailure({
            error: 'error: ',
          });
        }
      })
    )
  );

  fetchProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchProductDetail),
      switchMap((action) => {
        return this.http.get<ProductsDetail>(
          `${environment.NEW_API}/products/${action.productId}`
        );
      }),
      map((details) => {
        if (details) {
          return DashboardActions.fetchProductDetailSuccess({
            productDetails: details,
          });
        } else {
          return DashboardActions.fetchProductDetailFailure({
            error: 'error: ',
          });
        }
      })
    )
  );

  orderCheckout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.productCheckout),
      switchMap((action) => {
        let body: any = {
          successUrl: action.successUrl,
          cancelUrl: action.cancelUrl,
        };
        if (action.couponCode) {
          body.couponCode = action.couponCode;
        }
        return this.http.post<any>(
          environment.NEW_API + '/products/' + action.productId + '/checkout',
          body
        );
      }),
      map((data) => {
        return DashboardActions.productCheckoutSuccess({
          checkOutUrl: data,
        });
      }),
      catchError((error) => {
        return of(DashboardActions.productCheckoutFail({ error: error }));
      })
    );
  });

  orderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.productCheckoutSuccess),
        tap((action) => {
          window.location.href = action.checkOutUrl.data.checkOutUrl;
        })
      ),
    { dispatch: false }
  );
}
