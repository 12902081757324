import { Component, Input, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.scss'],
})
export class DocumentiComponent implements OnInit, OnDestroy, AfterViewInit {
  private storeSubscription: Subscription;
  public documentsFromStore: ProfiloDocumentDatum[];
  public loading: boolean = true;
  public typeDoc: string = 'diagnosis';
  public filtredDocs: ProfiloDocumentDatum[];
  @Input() docType: string;

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
    console.log(this.typeDoc)
    this.store.dispatch(ProfileActions.fetchDocuments());
    this.storeSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.documentsFromStore = profile.documents;
        this.filtredDocs = profile.documents;
        console.log(this.typeDoc, 'quiiiiiiiiiiiii')
        this.filtredDocs = this.documentsFromStore.filter(doc => doc.documentType === this.typeDoc.toUpperCase())
        this.loading = profile.loading;
        console.log('documents from store', this.documentsFromStore);
      });
  }

  ngAfterViewInit() {
    if (this.docType) {
      this.changeDocs(this.docType);
    }
  }

  changeDocs(string: string) {
    this.typeDoc = string
    this.filtredDocs = this.documentsFromStore.filter(doc => doc.documentType === this.typeDoc.toUpperCase())
    console.log(this.filtredDocs)
  }

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.docType) {
      this.docType = '';
    }
  }
}
